// @flow

import DOM from '../../util/dom';
import {extend, bindAll} from '../../util/util';
import {STYLES, MAP_STYLE_ID, MAP_STYLE_CONFIG} from '../../constants/map-styles';

import type Map from '../map';

type Options = {
    styleIds: string[]
};

const defaultOptions: Options = {
    styleIds: [MAP_STYLE_ID.VTRANS, MAP_STYLE_ID.VADMIN]
};

/**
 * A `MapStyleControl` control contains zoom buttons and a compass.
 *
 * @implements {IControl}
 * @param {Object} [options]
 * @param {String[]} [options.styleIds=[]] If `true` the compass button is included.
 * @example
 * var nav = new mapboxgl.MapStyleControl();
 * map.addControl(nav, 'top-left');
 * @see [Display map navigation controls](https://www.mapbox.com/mapbox-gl-js/example/navigation/)
 * @see [Add a third party vector tile source](https://www.mapbox.com/mapbox-gl-js/example/third-party/)
 */
class MapStyleControl {
    _map: Map;
    options: Options;
    _container: HTMLElement;
    _mapStyleBtn: HTMLElement;
    _currentStyleIndex: number;
    _titleEl: HTMLElement;

    constructor(options: Options) {
        this.options = extend({}, defaultOptions, options);
        this._container = DOM.create('div', 'vtmapgl-ctrl vtmapgl-ctrl-group');
        this._container.addEventListener('contextmenu', (e) => e.preventDefault());
        this._currentStyleIndex = 0;
        this._validateStyle(this.options.styleIds);
        bindAll([
            '_nextStyle',
            'updateMapStyle',
            '_updateStyleMiniMap',
            '_validateStyle',
            '_createMiniMap'
        ], this);
        this._mapStyleBtn = this._createMiniMap('vtmapgl-ctrl-minimap', 'Change style', this._nextStyle);
    }

    _getNextStyleIndex() {
        return (this._currentStyleIndex + 1) % this.options.styleIds.length;
    }

    _nextStyle() {
        const { styleIds } = this.options;
        const nextStyleIndex = this._getNextStyleIndex();
        this._map.setStyle(STYLES[styleIds[nextStyleIndex]]);
        this._currentStyleIndex = nextStyleIndex;
        this._updateStyleMiniMap();
    }

    onAdd(map: Map) {
        this._map = map;
        // Init default style
        this._map.setStyle(STYLES[this.options.styleIds[this._currentStyleIndex]]);
        this._updateStyleMiniMap();
        return this._container;
    }

    onRemove() {
        DOM.remove(this._container);
        delete this._map;
    }

    _createMiniMap(className: string, ariaLabel: string, fn: () => mixed) {
        const a = DOM.create('div', className, this._container);
        this._titleEl = DOM.create('div', 'map-style-title', a);
        a.title = ariaLabel;
        a.setAttribute('aria-label', ariaLabel);
        a.addEventListener('click', fn);
        return a;
    }

    _updateStyleMiniMap() {
        const mapStyleConfig = MAP_STYLE_CONFIG[this.options.styleIds[this._currentStyleIndex]];
        this._mapStyleBtn.style.backgroundImage = `url(${mapStyleConfig.miniMapUrl})`;
        this._titleEl.innerText = mapStyleConfig.title
    }

    updateMapStyle(mapStyleIds: string[]) {
        this._validateStyle(mapStyleIds);
        this.options.styleIds = mapStyleIds;
    }

    _validateStyle(mapStyleIds: string[]) {
        const allowedStyles = Object.values(MAP_STYLE_ID);
        for (let i = 0; i < mapStyleIds.length; i++) {
            const style = mapStyleIds[i];
            if (!allowedStyles.includes(style)) {
                throw new Error('STYLE_ID_NOT_EXIST');
            }
        }
    }
}

export default MapStyleControl;
