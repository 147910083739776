// @flow
import type { LngLatLike } from "../../geo/lng_lat";
import {extend} from '../../util/util';
import {degreesToRadians, radiansToDegrees} from '@turf/helpers';
import LngLatBounds from '../../geo/lng_lat_bounds';

type Options = {
    center: LngLatLike,
    radius: number
};

const defaultOptions: Options = {
    center: [0, 0],
    radius: 1
};

export default class GeoCircle {
    EARTH_RADIUS: number;
    distRatio: number;
    distRatioSine: number;
    distRatioCosine: number;
    startLatRad: number;
    startLonRad: number;
    startLatCos: number;
    startLatSin: number;
	delta: number; 				// Sinh cac diem tren duong tron theo tung goc 6 do

    constructor(options?: Options) {
        this.options = extend({}, defaultOptions, options);
        this.EARTH_RADIUS = 6378137;
        if (typeof(Number.prototype.toRad) === "undefined") {
            Number.prototype.toRad = function() {
                return this * Math.PI / 180;
            }
        }

        this.distRatio = this.options.radius / this.EARTH_RADIUS;
        this.distRatioSine = Math.sin(this.distRatio);
        this.distRatioCosine = Math.cos(this.distRatio);

        this.startLatRad = degreesToRadians(this.options.center[1]);
        this.startLonRad = degreesToRadians(this.options.center[0]);
        this.startLatCos = Math.cos(this.startLatRad);
        this.startLatSin = Math.sin(this.startLatRad);

        this.delta = 6;
    }

    _calLatLngByAngle(heading) {
        const endLatRads = Math.asin((this.startLatSin * this.distRatioCosine) + (this.startLatCos * this.distRatioSine * Math.cos(heading.toRad())));
        const endLonRads = this.startLonRad + Math.atan2(
			Math.sin(heading.toRad()) * this.distRatioSine * this.startLatCos,
            this.distRatioCosine - this.startLatSin * Math.sin(endLatRads));
		const lat = radiansToDegrees(endLatRads);
		const lng = radiansToDegrees(endLonRads);
		
        return [lng, lat];
    }

    // Lay cac diem tren duong tron, buoc nhay 6 degree. Tra ve mang LatLng
    getLatLngList() {
        const pts = [];
        for (let angle = 0; angle < 360; angle += this.delta) {
            pts.push(this._calLatLngByAngle(angle));
        }
        return pts;
    }

    // Tra ve diem LatLng tren duong trong
    getLatLng(angle) {
        return this._calLatLngByAngle(angle);
    }
    
    // Tra ve boundary duong tron
    getBounds() {
        const pt1 = this._calLatLngByAngle(45);
        const pt2 = this._calLatLngByAngle(45 + 180);
        return new LngLatBounds(pt1, pt2);
    }
}