import { API_DOMAIN } from '../constants/config';
import VTUtil from '../util/vtutil';

export class GeocoderAPIService {

    constructor({ accessToken }) {
      this._accessToken = accessToken;
    }

    /**
     *
     * @param {string} coordinate Tọa độ điểm, định dạng: lat,lng
     * @param {boolean} vtcallback Mặc định là null. Kết quả trả về là kiểu json nếu có giá trị null và kiểu javascript nếu có giá trị
     */
    fetchLatlngToAddress(coordinate, vtcallback) {
        const options = [];
        options.push('f=getaddr');
        options.push('pt=' + coordinate);
        options.push('k=' + this._accessToken);

        fetch(`${API_DOMAIN}placeapi/v2-old/place-api/VTMapService/geoprocessing?${options.join('&')}`).then(result => {
            result.json().then(data => {
                var items = [];
                if (data.status == 0) {
                    items.push({
                        id: 0,
                        location: coordinate,
                        name: data.data,
                        address: data.data,
                        type: 0,
                        website: "",
                        phone: "",
                        fax: "",
                        email: "",
                        description: "",
                        tourismInfo: "",
                        hasImage: false
                    });
                }
                
                var vtResult = {
                    items: items,
                    total: items.length
                };
                vtcallback(vtResult, data.status);
            });
        });
    }

    /**
     *
     * @param {string} coordinates Tọa độ nhiều điểm, định dạng: ['lat,lng','lat,lng','lat,lng'...]
     * @param {boolean} vtcallback Mặc định là null. Kết quả trả về là kiểu json nếu có giá trị null và kiểu javascript nếu có giá trị
     */
    fetchMultipleLatlngToAddress(coordinates, vtcallback) {
        const options = [];
        options.push('f=getmultiaddr');
        options.push('pt=' + coordinates.join(';'));
        options.push('k=' + this._accessToken);

        fetch(`${API_DOMAIN}placeapi/v2-old/place-api/VTMapService/geoprocessing?${options.join('&')}`).then(result => {
            result.json().then(data => {
                var items = [];
                if (data.status == 0) {
                    for (var i = 0; i < data.addresses.length; i++){
                        var addressResult = data.addresses[i];
                        items.push({
                            id: 0,
                            location: coordinates[i],
                            name: addressResult.address,
                            address: addressResult.address,
                            type: 0,
                            website: "",
                            phone: "",
                            fax: "",
                            email: "",
                            description: "",
                            tourismInfo: "",
                            hasImage: false
                        });
                    }
                }
                
                var vtResult = {
                    items: items,
                    total: items.length
                };
                vtcallback(vtResult, data.status);
            });
        });
    }

    /**
     *
     * @param {string} text Text
     * @param {string} offset Offset của kết quả
     * @param {string} limit Limit của kết quả
     * @param {boolean} vtcallback Mặc định là null. Kết quả trả về là kiểu json nếu có giá trị null và kiểu javascript nếu có giá trị
     */
    fetchTextToAddress(text, offset, limit, vtcallback) {
        const options = [];
        options.push('t=' + text);
        options.push('off=' + offset);
        options.push('lm=' + limit);
        options.push('k=' + this._accessToken);

        fetch(`${API_DOMAIN}placeapi/v2-old/place-api/VTMapService/placeService/geocoding?${options.join('&')}`).then(result => {
            result.json().then(data => {
                if (data.status != 0) {
                    vtcallback(data, status);
                    return;
                }
                var num = data.items.length;
                for (var i=0; i<num; i++) {
                    var oldItem = data.items[i];
                    var newItem = {
                        id: oldItem.id,
                        location: VTUtil.decryptPoint(oldItem.location),
                        name: oldItem.name,
                        address: oldItem.address,
                        type: oldItem.type,
                        website: oldItem.website,
                        phone: oldItem.phone,
                        fax: oldItem.fax,
                        email: oldItem.email,
                        description: oldItem.description,
                        tourismInfo: oldItem.tourismInfo,
                        hasImage: oldItem.hasImage
                    };
                    data.items[i] = newItem;
                }

                vtcallback(data, status)
            });
        });
    }

    /**
     *
     * @param {string} coordinate Tọa độ điểm, định dạng: lat,lng
     * @param {string} radius Bán kính tìm kiếm
     * @param {string} text Text
     * @param {string} offset Offset của kết quả
     * @param {string} limit Limit của kết quả
     * @param {boolean} vtcallback Mặc định là null. Kết quả trả về là kiểu json nếu có giá trị null và kiểu javascript nếu có giá trị
     */
    fetchSearchAround(coordinate, radius, type, text, offset, limit, vtcallback) {
        const options = [];
        options.push('f=search');
        options.push('pt=' + coordinate);
        options.push('t=' + type);
        options.push('r=' + radius);
        options.push('text=' + text);
        options.push('off=' + offset);
        options.push('lm=' + limit);
        options.push('k=' + this._accessToken);

        fetch(`${API_DOMAIN}placeapi/v2-old/place-api/VTMapService/placeService/geoprocessing?${options.join('&')}`).then(result => {
            result.json().then(data => {
                if (data.status != 0) {
                    vtcallback(data, data.status);
                    return;
                }
                var num = data.items.length;
                for (var i=0; i<num; i++) {
                    var oldItem = data.items[i];
                    var newItem = {
                        id: oldItem.id,
                        location: VTUtil.decryptPoint(oldItem.location),
                        name: oldItem.name,
                        address: oldItem.address,
                        type: oldItem.type,
                        website: oldItem.website,
                        phone: oldItem.phone,
                        fax: oldItem.fax,
                        email: oldItem.email,
                        description: oldItem.description,
                        tourismInfo: oldItem.tourismInfo,
                        hasImage: oldItem.hasImage
                    };
                    data.items[i] = newItem;
                }

                vtcallback(data, status);
            });
        });
    }
}
