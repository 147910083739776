// @flow
import { bindAll, extend } from '../../util/util';
import type Map from '../map';
import type { LngLatLike } from "../../geo/lng_lat";
import Marker from '../marker';
import turf from '@turf/turf';
import { EDIT_FEATURE_MARKER_IMG_URL } from '../../constants/config';
import Circle from './index';
import DOM from '../../util/dom';

const MARKER_TYPE = {
    BORDER: 'BORDER',
    CENTER: 'CENTER'
}

type MarkerInfo = {
    type: string
}

type Options = {
    circle: Circle
};

const defaultOptions: Options = {
};

/**
 * Creates a circle component
 * @param {Object} [options]
 * @param {HTMLElement} [options.element] DOM element to use as a circle. The default is a light blue, droplet-shaped SVG circle.
 * @param {string} [options.anchor='center'] A string indicating the part of the Circle that should be positioned closest to the coordinate set via {@link Circle#setLngLat}.
 *   Options are `'center'`, `'top'`, `'bottom'`, `'left'`, `'right'`, `'top-left'`, `'top-right'`, `'bottom-left'`, and `'bottom-right'`.
 * @param {PointLike} [options.offset] The offset in pixels as a {@link PointLike} object to apply relative to the element's center. Negatives indicate left and up.
 * @param {string} [options.color='#3FB1CE'] The color to use for the default circle if options.element is not provided. The default is light blue.
 * @param {boolean} [options.draggable=false] A boolean indicating whether or not a circle is able to be dragged to a new position on the map.
 * @example
 * var circle = new mapboxgl.Circle()
 *   .setLngLat([30.5, 50.5])
 *   .addTo(map);
 * @see [Add custom icons with Markers](https://www.mapbox.com/mapbox-gl-js/example/custom-circle-icons/)
 * @see [Create a draggable Circle](https://www.mapbox.com/mapbox-gl-js/example/drag-a-circle/)
 */
export default class CircleEditor {
    _map: Map;
    options: Options;
    _canvas: HTMLElement;
    _mapLoadedInterval: number;
    _borderMarkers: Marker[];
    _tempCircle: Circle;
    _circle: Circle;
    _isMarkerDrag: boolean;
    
    constructor(options?: Options) {
        this.options = extend({}, defaultOptions, options);
        this._circle = this.options.circle;
        this._map = this._circle.getMap();
        this._canvas = this._map.getCanvasContainer();
        this._circle = this.options.circle;
        this._mapLoadedInterval = null;
        this._borderMarkers = [];
        this._isMarkerDrag = false;

        bindAll([
            '_onMarkerDragend',
            '_onMarkerDrag',
            '_onMarkerMouseDown',
            '_onMarkerMouseUp',
        ], this);
    }

    activate() {
        this.deactivate();
        this.initMarkers();
    }

    deactivate() {
        this.removeMarkers();
        if (this._tempCircle) {
            this._tempCircle.remove();
        }
    }

    initMarkers() {
        const path = this._circle.getPath();
        if (!path || path.length < 1) return;
        // Add markers to 4 angles
        const fourAngleIndices = this._circle._getFourAngleIndex();
        this._borderMarkers = fourAngleIndices.map(i => {
            const cursor = i === fourAngleIndices[0] || i === fourAngleIndices[2] ? 'ns-resize' : 'ew-resize';
            return this.insertMarker(path[i], MARKER_TYPE.BORDER, cursor);
        });
        // Add center marker
        const center = this._circle.getCenter();
        this._borderMarkers.push(this.insertMarker(center, MARKER_TYPE.CENTER, 'move'));
    }

    insertMarker(lngLat: LngLatLike, markerType: string, cursor: string) {
        const element = this._createMarkerElement(EDIT_FEATURE_MARKER_IMG_URL);
        element.style.cursor = cursor;
        const marker = new Marker({
            draggable: true,
            element
        })
        .setLngLat(lngLat)
        .addTo(this._map);
        const markerInfo: MarkerInfo = {
            type: markerType
        }
        marker.markerInfo = markerInfo;
        element.addEventListener('mousedown', this._onMarkerMouseDown);
        element.addEventListener('mouseup', this._onMarkerMouseUp);
        marker.on('drag', this._onMarkerDrag);
        marker.on('dragend', this._onMarkerDragend);
        return marker;
    }

    _createMarkerElement(imgUrl: string): HTMLElement {
        const element = DOM.create('div')
        element.innerHTML = `<img src=${imgUrl} alt="middle-marker" style="width:12px;height:12px;">`;
        element.style.cursor = 'pointer';
        element.style.zIndex = 5;
        return element;
    }

    removeMarkers() {
        this._borderMarkers.forEach(marker => {
            marker.getElement().removeEventListener('mousedown', this._onMarkerMouseDown);
            marker.getElement().removeEventListener('mouseup', this._onMarkerMouseUp);
            marker.off('drag', this._onMarkerDrag);
            marker.off('dragend', this._onMarkerDragend);
            marker.remove();
        });
        this._borderMarkers = [];
    }

    hideMarkers() {
        this._borderMarkers.forEach(marker => {
            marker.remove();
        });
    }
    _onMarkerMouseDown(e: MouseEvent) {
        this._isMarkerDrag = true;
    }
    _onMarkerMouseUp(e: MouseEvent) {
        this._isMarkerDrag = false;
    }
    _onMarkerDrag(e: any) {
        const marker: Marker = e.target;
        const markerLnglat = marker.getLngLat().toArray();
        let tmpCenter;
        let tmpRadius;
        if (this.isBorderMarker(marker)) {
            tmpCenter = this._circle.getCenter();
            tmpRadius = turf.distance(turf.point(tmpCenter), turf.point(markerLnglat), {
                units: 'meters'
            });
        } else {
            tmpCenter = markerLnglat;
            tmpRadius = this._circle.getRadius();
        }
        this._createTempCircle(tmpCenter, tmpRadius);
    }

    _onMarkerDragend(e: any) {
        this._tempCircle.setVisible(false);
        const marker: Marker = e.target;
        const markerLnglat = marker.getLngLat().toArray();
        let center;
        let radius;
        if (this.isBorderMarker(marker)) {
            center = this._circle.getCenter();
            radius = turf.distance(turf.point(center), turf.point(markerLnglat), {
                units: 'meters'
            });
        } else {
            center = markerLnglat;
            radius = this._circle.getRadius();
        }
        this._circle.setCenterAndRadius(center, radius);
        this.updateMarkers(center, this._circle.getPath());
        this._isMarkerDrag = false;
    }

    updateMarkers(center: LngLatLike, path: LngLatLike[]) {
        this._circle._getFourAngleIndex().forEach((pathIndex, i) => {
            this._borderMarkers[i].setLngLat(path[pathIndex]).addTo(this._map);
        });
        this._borderMarkers[4].setLngLat(center).addTo(this._map);
    }

    _createTempCircle(center: LngLatLike, radius: number) {
        if (!this._tempCircle) {
            this._tempCircle = new Circle({
                center,
                radius,
                strokeColor: this._circle.getOptions().strokeColor,
                strokeWeight: 1,
                strokeOpacity: 0.5,
                fillColor: '#fff',
                fillOpacity: 0
            }).addTo(this._map);
        } else {
            this._tempCircle.setCenterAndRadius(center, radius);
            this._tempCircle.setVisible(true);
        }
    }

    isBorderMarker(marker: Marker): boolean {
        return marker && marker.markerInfo && marker.markerInfo.type === MARKER_TYPE.BORDER;
    }

    get isMarkerDrag(): boolean {
        return this._isMarkerDrag;
    }
}
