// @flow
import {bindAll, extend} from '../../util/util';
import {Evented} from '../../util/evented';
import type Map from '../map';
import type {MapMouseEvent, MapTouchEvent} from '../events';
import LngLat from "../../geo/lng_lat";
import type {LngLatLike} from "../../geo/lng_lat";
import {v4 as uuidv4} from 'uuid';
import LngLatBounds from '../../geo/lng_lat_bounds';
import CircleEditor from './circle_editor';
import turf from '@turf/turf';
import type { GeoJSONFeature } from '@mapbox/geojson-types';

type Options = {
    center: LngLatLike,
    radius: number,
    strokeColor: string,
    strokeOpacity: number,
    strokeWeight: number,
    fillColor: string,
    fillOpacity: number,
    zIndex: number,
    clickable: boolean,
    draggable: boolean,
    editable: boolean,
    visible: boolean
};

const defaultOptions: Options = {
    center: [0, 0],
    radius: 1,
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    zIndex: null,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true
};

const MAPPING_LAYER_PROPERTY: Map<string, string> = {
    strokeColor: 'line-color',
    strokeOpacity: 'line-opacity',
    strokeWeight: 'line-width',
    fillColor: 'fill-color',
    fillOpacity: 'fill-opacity',
};

/**
 * Creates a polygon component
 * @param {Object} [options]
 * @param {HTMLElement} [options.element] DOM element to use as a polygon. The default is a light blue, droplet-shaped SVG polygon.
 * @param {string} [options.anchor='center'] A string indicating the part of the Circle that should be positioned closest to the coordinate set via {@link Circle#setLngLat}.
 *   Options are `'center'`, `'top'`, `'bottom'`, `'left'`, `'right'`, `'top-left'`, `'top-right'`, `'bottom-left'`, and `'bottom-right'`.
 * @param {PointLike} [options.offset] The offset in pixels as a {@link PointLike} object to apply relative to the element's center. Negatives indicate left and up.
 * @param {string} [options.color='#3FB1CE'] The color to use for the default polygon if options.element is not provided. The default is light blue.
 * @param {boolean} [options.draggable=false] A boolean indicating whether or not a polygon is able to be dragged to a new position on the map.
 * @example
 * var polygon = new mapboxgl.Circle()
 *   .setLngLat([30.5, 50.5])
 *   .addTo(map);
 * @see [Add custom icons with Markers](https://www.mapbox.com/mapbox-gl-js/example/custom-polygon-icons/)
 * @see [Create a draggable Circle](https://www.mapbox.com/mapbox-gl-js/example/drag-a-polygon/)
 */
export default class Circle extends Evented {
    _map: Map;
    options: Options;
    _canvas: HTMLElement;
    SOURCE_ID: string;
    OUTLINE_ID: string;
    _mapLoadedInterval: number;
    EARTH_RADIUS: number;
    _editor: CircleEditor;
    _mouseDownLngLat: LngLat;
    _geojson: GeoJSONFeature;

    constructor(options?: Options) {
        super();
        this.options = extend({}, defaultOptions, options);
        this.SOURCE_ID = `CIRCLE_${uuidv4()}`;
        this.OUTLINE_ID = `${this.SOURCE_ID}_OUTLINE`;
        this._mapLoadedInterval = null;
        this.EARTH_RADIUS = 6378137;
        this._geojson = {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [[]]
            }
        }

        bindAll([
            '_addSource',
            '_connect',
            '_onClick',
            '_onDoubleClick',
            '_onMouseEnter',
            '_onMouseLeave',
            '_onMouseDown',
            '_onMouseMove',
            '_onMouseUp'
        ], this);
    }

    _connect() {
        this._map.off('load', this._connect);
        clearInterval(this._mapLoadedInterval);
        this._addSource();
        this.setClickable(this.options.clickable);
        this.setEditable(this.options.editable);
        this.setDraggable(this.options.draggable);
    }

    /**
     * Attaches the polygon to a map
     * @param {Map} map
     * @returns {Circle} `this`
     */
    addTo(map: Map) {
        this.remove();
        this._map = map;
        this._canvas = map.getCanvasContainer();

        if (map.loaded()) {
            this._connect();
        } else {
            map.on('load', this._connect);
            this._mapLoadedInterval = setInterval(() => {
                if (map.loaded()) this._connect();
            }, 16);
        }

        return this;
    }

    /**
     * Removes the polygon from a map
     * @example
     * var polygon = new vtmapgl.Circle().addTo(map);
     * polygon.remove();
     * @returns {Circle} `this`
     */
    remove() {
        if (this._map) {
            this._map.off('load', this._connect);
            clearInterval(this._mapLoadedInterval);
            this.setClickable(false);
            this.setEditable(false);
            this.setDraggable(false);
            if (this._map.getLayer(this.OUTLINE_ID)) {
                this._map.removeLayer(this.OUTLINE_ID);
            }
            if (this._map.getLayer(this.SOURCE_ID)) {
                this._map.removeLayer(this.SOURCE_ID);
            }
            if (this._map.getSource(this.SOURCE_ID)) {
                this._map.removeSource(this.SOURCE_ID);
            }
            delete this._map;
        }
        return this;
    }

    _addSource() {
        this._updateGeojsonSource();
        this._map.addSource(this.SOURCE_ID, {
            type: 'geojson',
            data: this._geojson
        });
        this._map.addLayer({
            id: this.SOURCE_ID,
            type: 'fill',
            source: this.SOURCE_ID,
            layout: {},
            paint: {
                'fill-color': this.options.fillColor,
                'fill-opacity': this.options.fillOpacity,
            }
        });
        // Draw outline
        this._map.addLayer({
            id: this.OUTLINE_ID,
            type: 'line',
            source: this.SOURCE_ID,
            layout: {},
            paint: {
                'line-color': this.options.strokeColor,
                'line-width': this.options.strokeWeight,
                'line-opacity': this.options.strokeOpacity,
            }
        });
    }

    _onClick(e: MapMouseEvent | MapTouchEvent) {
        e.preventDefault();
        this.fire('click', e);
    }

    _onDoubleClick(e: MapMouseEvent | MapTouchEvent) {
        e.preventDefault();
        this.fire('dblclick', e);
    }

    _onMouseEnter(e: MapMouseEvent | MapTouchEvent) {
        this._map.getCanvas().style.cursor = 'pointer';
    }

    _onMouseLeave(e: MapMouseEvent | MapTouchEvent) {
        this._map.getCanvas().style.cursor = '';
    }

    setClickable(shouldBeClickable: boolean) {
        this.options.clickable = !!shouldBeClickable;
        if (this._map) {
            if (shouldBeClickable) {
                this._map.on('mouseenter', this.SOURCE_ID, this._onMouseEnter);
                this._map.on('mouseleave', this.SOURCE_ID, this._onMouseLeave);

                this._map.on('click', this.SOURCE_ID, this._onClick);
                this._map.on('dblclick', this.SOURCE_ID, this._onDoubleClick);
            } else {
                if (!this.options.draggable) {
                    this._map.off('mouseenter', this.SOURCE_ID, this._onMouseEnter);
                    this._map.off('mouseleave', this.SOURCE_ID, this._onMouseLeave);
                }
                this._map.off('click', this.SOURCE_ID, this._onClick);
                this._map.off('dblclick', this.SOURCE_ID, this._onDoubleClick);
            }
        }
        return;
    }

    setEditable(editable: boolean) {
        this.options.editable = !!editable;
        if (this._map) {
            if (editable) {
                if (!this._editor) {
                    this._editor = new CircleEditor({
                        circle: this
                    });
                }
                this._editor.activate();
            } else {
                if (this._editor) {
                    this._editor.deactivate();
                    this._editor = null;
                }
            }
        }
        return this;
    }

    _generateCircleSource(center: LngLatLike, radiusInMeter: number): GeoJSON {
        // Using turf
        const options = {units: 'meters'};
        const circle = turf.circle(center, radiusInMeter, options);
        return circle;
    }

    isRadiusChanged(radius: number) {
        return this.options.radius !== radius;
    }
    setRadius(radius: number) {
        if (radius === null || radius === undefined) return;
        const radiusChanged = this.isRadiusChanged(radius);
        this.options.radius = radius;
        this._updateGeojsonSource();
        this._map.getSource(this.SOURCE_ID).setData(this._geojson);
        if (radiusChanged) this.fire('radius_changed');
    }
    
    isCenterChanged(center: LngLatLike) {
        return this.options.center[0] !== center[0] || this.options.center[1] !== center[1];
    }
    setCenter(center: LngLatLike) {
        if (!center) return;
        const centerChanged = this.isCenterChanged(center);
        this.options.center = center;
        this._updateGeojsonSource();
        this._map.getSource(this.SOURCE_ID).setData(this._geojson);
        if (centerChanged) this.fire('center_changed');
    }

    setCenterAndRadius(center: LngLatLike, radius: number) {
        if (!center) return;
        if (radius === null || radius === undefined) return;
        const centerChanged = this.isCenterChanged(center);
        const radiusChanged = this.isRadiusChanged(radius);
        this.options.center = center;
        this.options.radius = radius;
        this._updateGeojsonSource();
        this._map.getSource(this.SOURCE_ID).setData(this._geojson);
        if (centerChanged) this.fire('center_changed');
        if (radiusChanged) this.fire('radius_changed');
    }

    _updateGeojsonSource() {
        this._geojson = this._generateCircleSource(this.options.center, this.options.radius);
    }

    setPaints(options: any) {
        if (!this._map.getLayer(this.SOURCE_ID)) {
            return;
        }
        this.options = extend({}, this.options, options);
        const possibleProperties = Object.keys(MAPPING_LAYER_PROPERTY);

        Object.entries(options).forEach(([key, value]) => {
            if (!possibleProperties.includes(key)) return;
            const layerProperty = MAPPING_LAYER_PROPERTY[key];
            if (layerProperty.startsWith('fill')) {
                this._map.setPaintProperty(this.SOURCE_ID, layerProperty, value);
            }
            if (layerProperty.startsWith('line')) {
                this._map.setPaintProperty(this.OUTLINE_ID, layerProperty, value);
            }
        });
    }

    getBounds(): LngLatBounds {
        const path = this.getPath();
        return this._getFourAngleIndex().reduce((bounds, i) => bounds.extend(path[i]), new LngLatBounds());
    }

    getMap(): Map {
        return this._map;
    }

    getRadius(): number {
        return this.options.radius;
    }

    getCenter(): LngLatLike {
        return this.options.center;
    }

    getPath(): LngLatLike[] {
        return JSON.parse(JSON.stringify(this._geojson.geometry.coordinates[0]));
    }

    getOptions() {
        return this.options;
    }

    setVisible(visible: boolean) {
        this.options.visible = !!visible;
        if (this._map) {
            this._map.setLayoutProperty(this.SOURCE_ID, 'visibility', this.options.visible ? 'visible' : 'none');
            this._map.setLayoutProperty(this.OUTLINE_ID, 'visibility', this.options.visible ? 'visible' : 'none')
        }
    }

    setDraggable(draggable: boolean) {
        this.options.draggable = !!draggable;
        if (this._map) {
            if (this.options.draggable) {
                if (!this.options.clickable) {
                    this._map.on('mouseenter', this.SOURCE_ID, this._onMouseEnter);
                    // Change it back to a pointer when it leaves.
                    this._map.on('mouseleave', this.SOURCE_ID, this._onMouseLeave);
                }
                this._map.on('mousedown', this.SOURCE_ID, this._onMouseDown);
                this._map.on('touchstart', this.SOURCE_ID, this._onTouchStart);

            } else {
                if (!this.options.clickable) {
                    this._map.off('mouseenter', this.SOURCE_ID, this._onMouseEnter);
                    this._map.off('mouseleave', this.SOURCE_ID, this._onMouseLeave);
                }
                this._map.off('mousedown', this.SOURCE_ID, this._onMouseDown);
                this._map.off('touchstart', this.SOURCE_ID, this._onTouchStart);
            }
        }
        return this;
    }

    _onMouseDown(e: MapMouseEvent) {
        if (this._editor.isMarkerDrag) {
            return;
        }
        e.preventDefault();
        this._mouseDownLngLat = e.lngLat;
        this._editor.hideMarkers();
        this._map.on('mousemove', this._onMouseMove);
        this._map.once('mouseup', this._onMouseUp);
    }

    _onTouchStart(e: MapTouchEvent) {
        if (this._editor.isMarkerDrag) {
            return;
        }
        e.preventDefault();
        this._mouseDownLngLat = e.lngLat;
        this._editor.hideMarkers();
        this._map.on('touchmove', this._onMouseMove);
        this._map.once('touchend', this._onMouseUp);
    }

    _onMouseMove(e: MapMouseEvent | MapTouchEvent) {
        // Create transformCircle geojson
        this.options.center = this._getTransformCenter(this.getCenter(), this._mouseDownLngLat.toArray(), e.lngLat.toArray());
        this._updateGeojsonSource();
        this._map.getSource(this.SOURCE_ID).setData(this._geojson);
        this._mouseDownLngLat = e.lngLat;
    }

    _onMouseUp(e: MapMouseEvent | MapTouchEvent) {
        this._editor.updateMarkers(this.getCenter(), this.getPath());
        this._map.off('mousemove', this._onMouseMove);
        this._map.off('touchmove', this._onMouseMove);
        this.fire('center_changed');
    }

    _getTransformCenter(currentCenter: LngLatLike, srcDirectPoint: LngLatLike, desDirectPoint: LngLatLike): LngLatLike {
        const sourceDirectionPoint = turf.point(srcDirectPoint);
        const destinationDirectionPoint = turf.point(desDirectPoint);
        const distance = turf.distance(sourceDirectionPoint, destinationDirectionPoint);
        const bearing = turf.bearing(sourceDirectionPoint, destinationDirectionPoint);
        const currentCenterPoint = turf.point(currentCenter);
        const newCenter = turf.destination(currentCenterPoint, distance, bearing);
        return newCenter.geometry.coordinates;
    }

    _getFourAngleIndex(): number[] {
        const pathLength = this._geojson.geometry.coordinates[0].length - 1;
        return [0, 1, 2, 3].map(i => pathLength/4*i);
    }
}
