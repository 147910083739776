// @flow
import { bindAll, extend } from '../util/util';
import { Evented } from '../util/evented';
import type Map from './map';
import type { LngLatLike } from "../geo/lng_lat";
import LngLat from '../geo/lng_lat';
import { MapMouseEvent } from './events';
import turf from '@turf/turf';
import Polyline from './polyline';

type Options = {
    polyline: Polyline
};

const defaultOptions: Options = {
};

/**
 * Creates a polyline component
 * @param {Object} [options]
 * @param {HTMLElement} [options.element] DOM element to use as a polyline. The default is a light blue, droplet-shaped SVG polyline.
 * @param {string} [options.anchor='center'] A string indicating the part of the PolylineDraw that should be positioned closest to the coordinate set via {@link PolylineDraw#setLngLat}.
 *   Options are `'center'`, `'top'`, `'bottom'`, `'left'`, `'right'`, `'top-left'`, `'top-right'`, `'bottom-left'`, and `'bottom-right'`.
 * @param {PointLike} [options.offset] The offset in pixels as a {@link PointLike} object to apply relative to the element's center. Negatives indicate left and up.
 * @param {string} [options.color='#3FB1CE'] The color to use for the default polyline if options.element is not provided. The default is light blue.
 * @param {boolean} [options.draggable=false] A boolean indicating whether or not a polyline is able to be dragged to a new position on the map.
 * @example
 * var polyline = new mapboxgl.PolylineDraw()
 *   .setLngLat([30.5, 50.5])
 *   .addTo(map);
 * @see [Add custom icons with Markers](https://www.mapbox.com/mapbox-gl-js/example/custom-polyline-icons/)
 * @see [Create a draggable PolylineDraw](https://www.mapbox.com/mapbox-gl-js/example/drag-a-polyline/)
 */
export default class PolylineDraw extends Evented {
    _map: Map;
    options: Options;
    _canvas: HTMLElement;
    _path: LngLatLike[];
    _polyline: Polyline;
    _tempPolyline: Polyline;
    _oldClickable: boolean;
    _oldDraggable: boolean;
    _oldEditable: boolean;
    _oldVisible: boolean;

    constructor(options?: Options) {
        super();
        this.options = extend({}, defaultOptions, options);
        this._polyline = this.options.polyline;
        this._path = this.options.polyline.getPath();
        this._map = this._polyline.getMap();
        this._canvas = this._map.getCanvasContainer();
        this._oldClickable = this._polyline.getOptions().clickable;
        this._oldDraggable = this._polyline.getOptions().draggable;
        this._oldEditable = this._polyline.getOptions().editable;
        this._oldVisible = this._polyline.getOptions().visible;

        bindAll([
            '_addEvents',
            '_handleClickMap',
            '_handleMouseMove',
            '_handleDoubleClickMap'
        ], this);
    }

    _addEvents() {
        this._map.on('click', this._handleClickMap);
        // temp polyline mouse move
        this._map.on('mousemove', this._handleMouseMove);
        this._map.on('dblclick', this._handleDoubleClickMap);
    }

    _removeEvents() {
        this._map.off('click', this._handleClickMap);
        this._map.off('mousemove', this._handleMouseMove);
        this._map.off('dblclick', this._handleDoubleClickMap);
    }

    _handleClickMap(e: MapMouseEvent | MapTouchEvent) {
        this.addNewPoint(e.lngLat);
    }

    _handleMouseMove(e: MapMouseEvent) {
        const pathLength = this._path.length;
        if (pathLength < 1) {
            return;
        }
        const mouseLngLat = e.lngLat;
        const lastPoint = this._path[pathLength - 1];
        this._tempPolyline.setPath([lastPoint, mouseLngLat.toArray()]);
    }

    _handleDoubleClickMap(e: MapMouseEvent) {
        e.preventDefault();
        this.onCompleteDraw();
    }

    _createTmpPolyline() {
        const opts = {
            strokeColor: this._polyline.getOptions().strokeColor,
            clickable: false,
            strokeWeight: this._polyline.getOptions().strokeWeight,
            strokeOpacity: 0.4,
            strokeDashstyle: 'dashdot'
        }
        this._tempPolyline = new Polyline(opts).addTo(this._map);
    }

    activate() {
        this._map.doubleClickZoom.disable();
        this._polyline.setClickable(false);
        this._polyline.setEditable(false);
        this._polyline.setDraggable(false);
        // this._polyline.setVisible(true);
        // Create temp polyline
        this._createTmpPolyline();
        this._addEvents();
    }

    deactivate() {
        this._map.doubleClickZoom.enable();
        this._polyline.setClickable(this._oldClickable);
        this._polyline.setEditable(this._oldEditable);
        this._polyline.setDraggable(this._oldDraggable);
        // this._polyline.setVisible(this._oldVisible);
        this._tempPolyline.remove();
        this._removeEvents();
    }

    // Tao them diem moi
    addNewPoint(lngLat: LngLat) {
        const pathLength = this._path.length;
        if (pathLength > 0) {
            const lastPoint = this._path[pathLength - 1];
            // tmpPath.setAt(0, lastPoint);
            if (turf.booleanEqual(turf.point(lngLat.toArray()), turf.point(lastPoint))) {
                return;
            }
        }
        this._path.push(lngLat.toArray());
        // ve lai hinh
        this.updatePath();
    }

    updatePath() {
        this._polyline.setPath(this._path);
    }

    onCompleteDraw() {
        // Truong hop dblclick ngay tu dau se khong luu lai polyline do
        if (this._path.length < 2) {
            return;
        }
        this._polyline.setDrawing(false);
        this._polyline.fire('endDraw');
        delete this._map;
    }
}
