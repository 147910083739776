import LngLat from '../geo/lng_lat';
// import type {LngLatLike} from '../geo/lng_lat';
// import {VTObjChecker} from './VTObjChecker';
import turf from '@turf/turf';
// const EARTH_RADIUS = 6378137; // meters

/*
 * Ham tien ich tinh toan tren ban do
 */
export class GeometryUtil {
    // CLASSNAME: "viettel.MapOptions",

    // getLength: function(path, isClosed) {
    //     var length = 0;
    //     if (!VTObjChecker.isBoolean(isClosed)) {
    //         isClosed = false;
    //     }
    //     if (VTObjChecker.isMVCArray(path)) {
    //         // MVCArray
    //         for (var i = 0; i < path.getLength()-1; i++) {
    //             length = length + viettel.GeometryUtil.getDistanceBetween(path.getAt(i), path.getAt(i+1));
    //         }
    //         if (isClosed && path.getLength()>=2) {
    //             length = length + viettel.GeometryUtil.getDistanceBetween(path.getAt(0), path.getAt(path.getLength()-1));
    //         }
    //     }
    //     else {
    //         // Array thuong
    //         for (var i = 0; i < path.length - 1; i++) {
    //             length = length + viettel.GeometryUtil.getDistanceBetween(path[i], path[i+1]);
    //         }
    //         if (isClosed && path.length>=2) {
    //             length = length + viettel.GeometryUtil.getDistanceBetween(path[0], path[path.length-1]);
    //         }
    //     }
    //     return Math.round(length);
    // },
    /* Tinh khoang cach 2 diem
	*/
    static getDistanceBetween(srcPoint, desPoint) {
        let sourcePoint = srcPoint;
        if (srcPoint instanceof LngLat) {
            sourcePoint = [srcPoint.lng, srcPoint.lat];
        }
        let destinationPoint = desPoint;
        if (desPoint instanceof LngLat) {
            destinationPoint = [desPoint.lng, desPoint.lat]
        }
        return turf.distance(turf.point(sourcePoint), turf.point(destinationPoint), { units: 'meters' });
    }

    // /*
    //  *Giai thuat nay chi moi duoc test doi voi polygon khep kin ko co diem tu cat+ canh ngang+
    //  *diem cat nam tren ria.
    //  *Giai thuat: Duoc base dua tren http://alienryderflex.com/polygon/
    //  *polygon o day la list cac diem co the la Array
    //  *
    //  *
    //  */
    // checkPointInPolygon : function(point, polygon){
    //     var polyLength = polygon.length;
    //     if(polyLength <= 0){
    //         return false;
    //     }
    //     var i;
    //     var j = polyLength - 1;
    //     var oddNodes = false;

    //     for (i=0; i<polyLength; i++) {
    //         if (polygon[i].lat() < point.lat() && polygon[j].lat() >=  point.lat()
    //             ||  polygon[j].lat() < point.lat() && polygon[i].lat() >= point.lat()) {
    //             if (polygon[i].lng() + (point.lat() - polygon[i].lat())/(polygon[j].lat() - polygon[i].lat())*(polygon[j].lng() - polygon[i].lng())< point.lng()) {
    //                 oddNodes=!oddNodes;
    //             }
    //         }
    //         j=i;
    //     }
    //     return oddNodes;
    // },

    // computeOffset: function(firstPoint, distance, heading, radius){
    //     var R = EARTH_RADIUS; // Radius of the earth in m
    //     if(VTObjChecker.isObject(radius)){
    //         R = radius;
    //     }
    //     if (typeof(Number.prototype.toRad) === "undefined") {
    //         Number.prototype.toRad = function() {
    //             return this * Math.PI / 180;
    //         }
    //     }

    //     var distRatio = distance / R;
    //     var distRatioSine = Math.sin(distRatio);
    //     var distRatioCosine = Math.cos(distRatio);

    //     var startLatRad = viettel.GeometryUtil.degreesToRadians(firstPoint.lat());
    //     var startLonRad = viettel.GeometryUtil.degreesToRadians(firstPoint.lng());
    //     var startLatCos = Math.cos(startLatRad);
    //     var startLatSin = Math.sin(startLatRad);

    //     var endLatRads = Math.asin((startLatSin * distRatioCosine) + (startLatCos * distRatioSine * Math.cos(heading.toRad())));
    //     var endLonRads = startLonRad + Math.atan2(
	// 		Math.sin(heading.toRad()) * distRatioSine * startLatCos,
    //         distRatioCosine - startLatSin * Math.sin(endLatRads));
    //     return new viettel.LatLng(viettel.GeometryUtil.radiansToDegrees(endLatRads), viettel.GeometryUtil.radiansToDegrees(endLonRads));
    // },
	// computeOffsetOrigin: function(lastPoint, distance, heading, radius){
    //     return viettel.GeometryUtil.computeOffset(lastPoint, distance, heading + 180, radius);
    // },
	// computeHeading: function(sourcePoint,destinationPoint){
	// 	var dLon = viettel.GeometryUtil.degreesToRadians(destinationPoint.lng() - sourcePoint.lng());
	// 	var lat2 = viettel.GeometryUtil.degreesToRadians(destinationPoint.lat());
	// 	var lat1 = viettel.GeometryUtil.degreesToRadians(sourcePoint.lat());

	// 	var y = Math.sin(dLon) * Math.cos(lat2);
	// 	var x = Math.cos(lat1)*Math.sin(lat2) -
    //     Math.sin(lat1)*Math.cos(lat2)*Math.cos(dLon);
	// 	var brng = viettel.GeometryUtil.radiansToDegrees(Math.atan2(y, x));
	// 	return brng;
	// },
    // degreesToRadians: function(degrees){
    //     var degToRadFactor = Math.PI / 180;
    //     return degrees * degToRadFactor;
    // },
    // radiansToDegrees: function(radians){
    //     var radToDegFactor = 180 / Math.PI;
    //     return radians * radToDegFactor;
    // },
    // area2: function(p0, p1, p2) {
    //     return (p1.lat() - p0.lat()) * (p2.lng() - p0.lng()) - (p2.lat() - p0.lat()) * (p1.lng() - p0.lng());
    // },
    // computeArea: function(points, radius) {
	// 	var R = EARTH_RADIUS;
	// 	var isLeft = false;
	// 	var len = (VTObjChecker.isObject(points)?points.length:0);
    //     if(VTObjChecker.isObject(radius)){
    //         R = radius;
    //     }
    //     var area = 0.0;
    //     if (len > 2) {
    //         var p1, p2;
    //         for(var i=0; i<len; i++) {
    //             p1 = points[i];
    //             if (i+1<len)
    //                 p2 = points[i+1];
    //             else
    //                 p2 = points[0];
    //             area += viettel.GeometryUtil.degreesToRadians(p2.lng() - p1.lng()) *
    //             (2 + Math.sin(viettel.GeometryUtil.degreesToRadians(p1.lat())) + Math.sin(viettel.GeometryUtil.degreesToRadians(p2.lat())));
    //         }
    //         area = area * R * R / 2.0;
    //     }
    //     return Math.abs(area);
    // },
	// getArea: function(points, radius) {
	// 	var R = EARTH_RADIUS;
	// 	var isLeft = false;
	// 	var len = (VTObjChecker.isObject(points)?points.length:0);
    //     if(VTObjChecker.isObject(radius)){
    //         R = radius;
    //     }
    //     var area = 0.0;
    //     if (len > 2) {
    //         var p1, p2;
    //         for(var i=0; i<len; i++) {
    //             p1 = points[i];
    //             if (i+1<len)
    //                 p2 = points[i+1];
    //             else
    //                 p2 = points[0];
    //             area += viettel.GeometryUtil.degreesToRadians(p2.lng() - p1.lng()) *
    //             (2 + Math.sin(viettel.GeometryUtil.degreesToRadians(p1.lat())) + Math.sin(viettel.GeometryUtil.degreesToRadians(p2.lat())));
    //         }
    //         area = area * R * R / 2.0;
    //     }
    //     return Math.abs(area);
    // },
    // computeSignedArea: function(points, radius) {
	// 	var R = EARTH_RADIUS;
	// 	var isLeft = false;
	// 	var len = (VTObjChecker.isObject(points)?points.length:0);
    //     if(VTObjChecker.isObject(radius)){
    //         R = radius;
    //     }
	// 	if (len > 2) {
	// 		isLeft = this.area2(points[0],points[1], points[2])>0?true:false;
	// 	}
    //     var area = viettel.GeometryUtil.computeArea(points, R);
	// 	if (isLeft) {
	// 		area = -area;
	// 	}
    //     return area;
    // },
	// interpolate: function (sourcePoint, destinationPoint, fraction) {
	// 	var distance = viettel.GeometryUtil.getDistanceBetween(sourcePoint, destinationPoint) * fraction;
	// 	var heading = viettel.GeometryUtil.computeHeading(sourcePoint, destinationPoint);
	// 	return viettel.GeometryUtil.computeOffset(sourcePoint, distance, heading);
	// },
    // _distance2: function(pt1, pt2) {
    //     var ux = pt2.lng() - pt1.lng();
    //     var uy = pt2.lat() - pt1.lat();
    //     return (ux*ux + uy*uy);
    // },

    // _projectPointOnSegment: function(pt, pt1, pt2) {
    //     var ret = {
    //         distance: 0.0,      // Binh phuong khoang cach
    //         pt: null,
    //         flag: -1
    //     };
    //     var ux = pt2.lng() - pt1.lng();
    //     var uy = pt2.lat() - pt1.lat();
    //     if (ux == 0 && uy == 0) {
    //         ret.pt = new viettel.LatLng(pt1.lat(), pt1.lng());
    //         ret.flag = 0;
    //         ret.distance = viettel.GeometryUtil._distance2(pt, ret.pt);
    //         return ret;
    //     }

    //     var a1 = uy;
    //     var b1 = -ux;
    //     var c1 = uy * pt1.lng() - ux * pt1.lat();
    //     var a2 = ux;
    //     var b2 = uy;
    //     var c2 = ux * pt.lng() + uy * pt.lat();
    //     var d = a1 * b2 - a2 * b1;
    //     var dx = c1 * b2 - c2 * b1;
    //     var dy = a1 * c2 - a2 * c1;
    //     var x = dx / d;
    //     var y = dy / d;
    //     ret.pt = new viettel.LatLng(y, x);
    //     ret.flag = 0;
    //     ret.distance = viettel.GeometryUtil._distance2(pt, ret.pt);

    //     // Xac dinh vi tri diem chieu so doan
    //     var uv = (pt1.lng() - x) * (pt2.lng() - x) + (pt1.lat() - y) * (pt2.lat() - y);
    //     if (uv > 0) {
    //         // Diem chieu nam ngoai duong
    //         uv = (x - pt1.lng()) * (pt2.lng() - pt1.lng()) + (y - pt1.lat()) * (pt2.lat() - pt1.lat());
    //         if (uv < 0) {
    //             ret.flag = -1;
    //         } else {
    //             ret.flag = 1;
    //         }
    //     }

    //     return ret;
    // },

    // projectPointOnSegment: function(pt, pt1, pt2) {
    //     var ret = viettel.GeometryUtil._projectPointOnSegment(pt, pt1, pt2);
    //     if (ret.pt!=null)
    //         ret.distance = viettel.GeometryUtil.getDistanceBetween(pt, ret.pt);
    //     else
    //         ret.distance = 0.0;
    //     return ret;
    // },

    // distancePointToPoly: function(pt, points) {
    //     var ret = {
    //         distance: 0.0,
    //         pt: null,
    //         index: -1
    //     };
    //     var len = (VTObjChecker.isObject(points)?points.length:0);
    //     if (len<2) return ret;

    //     var xProj = points[0].lng();
    //     var yProj = points[0].lat();
    //     var idxMin = -1;
    //     var minDistance = 1e12;
    //     for (var i = 0; i < len - 1; i++) {
    //         var tmp = viettel.GeometryUtil._projectPointOnSegment(pt, points[i], points[i + 1]);
    //         if (tmp.flag!=0) {
    //             var min1 = viettel.GeometryUtil._distance2(pt, points[i]);
    //             var min2 = viettel.GeometryUtil._distance2(pt, points[i + 1]);
    //             if (min1<min2) {
    //                 tmp.distance = min1;
    //                 tmp.pt = points[i];
    //             }
    //             else {
    //                 tmp.distance = min2;
    //                 tmp.pt = points[i+1];
    //             }
    //         }
    //         if (tmp.distance < minDistance) {
    //             xProj = tmp.pt.lng();
    //             yProj = tmp.pt.lat();
    //             minDistance = tmp.distance;
    //             idxMin = i;
    //         }
    //     }

    //     ret.index = idxMin;
    //     ret.pt = new viettel.LatLng(yProj, xProj);
    //     ret.distance = viettel.GeometryUtil.getDistanceBetween(pt, ret.pt);

    //     return ret;
    // },

	// isLocationOnEdge: function (point, poly, tolerance){
	// 	var distance = viettel.GeometryUtil.distancePointToPoly(point, poly);
	// 	if (distance.distance < tolerance) {
	// 		return true;
	// 	} else
	// 		return false;
	// },

	// decodePath: function(encoded, precision){
	// 	if (!VTObjChecker.isObject(precision)){
	// 		precision = 1/1e5;
	// 	}

    //     var len = encoded.length, index=0, lat=0.0, lng = 0.0, array = [];
    //     while (index < len) {
    //         var b, shift = 0, result = 0;
    //         do {
    //             b = encoded.charCodeAt(index++) - 63;
    //             result |= (b & 0x1f) << shift;
    //             shift += 5;
    //         } while (b >= 0x20);
    //         var dlat = ((result & 1) ? ~(result >> 1) : (result >> 1));
    //         lat += dlat;
    //         shift = 0;
    //         result = 0;
    //         do {
    //             b = encoded.charCodeAt(index++) - 63;
    //             result |= (b & 0x1f) << shift;
    //             shift += 5;
    //         } while (b >= 0x20);
    //         var dlng = ((result & 1) ? ~(result >> 1) : (result >> 1));
    //         lng += dlng;
    //         array.push(new viettel.LatLng(lat * precision, lng * precision));
    //     }
    //     return array;
    // },

	// encodeNumber: function(num) {
	// 	//console.log("sgn_num:",num);
    //     var encodeString = '';
	// 	var value;
    //     while (num >= 0x20) {
    //         value = (0x20 | (num & 0x1f)) + 63;
    //         encodeString += (String.fromCharCode(value));
    //         num >>= 5;
    //     }

    //     value = num + 63;
    //     encodeString += (String.fromCharCode(value));
	// 	//console.log("encodeString:",encodeString);
    //     return encodeString;
    // },
    // encodeSignedNumber: function(num) {
    //     var sgn_num = num << 1;
    //     if (num < 0) {
    //         sgn_num = ~(sgn_num);
    //     }
    //     return (this.encodeNumber(sgn_num));
    // },

    // encodePath: function(points) {
    //     if (points==null) return "";
    //     var size = points.length;

    //     var encodeString = "";
	// 	for (var i=0; i<size; i++) {
	// 		if (i < 1){
	// 			encodeString += this.encodeSignedNumber(Math.round(points[i].lat()*1e5));
	// 			encodeString += this.encodeSignedNumber(Math.round(points[i].lng()*1e5));
	// 		} else{
	// 			var deltaLat = points[i].lat() - points[i-1].lat();
	// 			var deltaLng = points[i].lng() - points[i-1].lng();
	// 			encodeString += this.encodeSignedNumber(Math.round(deltaLat*1e5));
	// 			encodeString += this.encodeSignedNumber(Math.round(deltaLng*1e5));
	// 		}
	// 	}

    //     return encodeString.toString();
    // },
	// converterMVCArrayToArray: function(_MVCArray) {
	// 	if (VTObjChecker.isMVCArray(_MVCArray) && _MVCArray.getLength()>=0) {
	// 		if (_MVCArray.getLength() == 0) {
	// 			return new Array();
	// 		}
	// 		// Dinh dang MVCArray
	// 		var obj0 = _MVCArray.getAt(0);
	// 		if (VTObjChecker.isLatLngObj(obj0)) {
	// 			var path = new Array();
	// 			for (var i = 0, size = _MVCArray.getLength(); i < size; i++) {
	// 				path.push(_MVCArray.getAt(i));
	// 			}
	// 			return path;
	// 		} else
	// 			return null;
	// 	} else
	// 		return null;
	// },
	// converterArrayToMVCArray: function(_Array) {
	// 	if (VTObjChecker.isArray(_Array) && _Array.length>=0) {
	// 		if (_Array.length == 0) {
	// 			return new viettel.MVCArray();
	// 		}
	// 		// Dinh dang MVCArray
	// 		var obj0 = _Array[0];
	// 		if (VTObjChecker.isLatLngObj(obj0)) {
	// 			var path = new viettel.MVCArray();
	// 			for (var i = 0, size = _Array.length; i < size; i++) {
	// 				path.push(_Array[i]);
	// 			}
	// 			return path;
	// 		} else
	// 			return null;
	// 	} else
	// 		return null;
	// },

	// get4326Resolution: function(zoomLevel) {
	// 	var resolutions =  new Array(
    //             0.703125,                       // Muc 00
    //             0.3515625,                      // Muc 01
    //             0.17578125,                     // Muc 02
    //             0.087890625,                    // Muc 03
    //             0.0439453125,                   // Muc 04
    //             0.02197265625,                  // Muc 05
    //             0.010986328125,					// Muc 06
    //             0.0054931640625,				// Muc 07
    //             0.00274658203125,				// Muc 08
    //             0.001373291015625,				// Muc 09
    //             6.866455078125E-4,				// Muc 10
    //             3.4332275390625E-4,				// Muc 11
    //             1.71661376953125E-4,			// Muc 12
    //             8.58306884765625E-5,			// Muc 13
    //             4.291534423828125E-5,			// Muc 14
    //             2.1457672119140625E-5,			// Muc 15
    //             1.0728836059570312E-5,			// Muc 16
    //             5.364418029785156E-6,			// Muc 17
    //             2.682209014892578E-6,           // Muc 18
    //             1.341104507446289E-6			// Muc 19
    //     );
	// 	return resolutions[zoomLevel];
	// },

	// /**
	//  * Reduce path theo tung muc resolution
	//  * Return MVCArray
	//  */
	// reducePath: function(path, map) {
	// 	if (VTObjChecker.isMVCArray(path)) {
	// 		if (path==null || path.getLength()==0 || map == null) return null;
	// 	} else if (VTObjChecker.isArray(path)){
	// 		if (path==null || path.lenght==0 || map == null) return null;
	// 		path = viettel.GeometryUtil.converterArrayToMVCArray(path);
	// 	} else
	// 		return null;

	// 	var zoomLevel = map.getZoom();
	// 	var reduceResolution = viettel.GeometryUtil.get4326Resolution(zoomLevel);

	// 	var pts = new viettel.MVCArray();
	// 	var ptPre = path.getAt(0);
	// 	pts.push(ptPre);

	// 	var num = path.getLength();
    //     var pt;
    //     var dLat, dLng;
    //     var dLatLngCheck = reduceResolution;
    //     for (var k = 1; k < num-1; k++) {
    //     	pt = path.getAt(k);
    //     	dLat = Math.abs(pt.lat() - ptPre.lat());
    //     	dLng = Math.abs(pt.lng() - ptPre.lng());
    //     	if (!(dLat<=dLatLngCheck && dLng<=dLatLngCheck)){
    //     		pts.push(pt);
    //     		ptPre = pt;
    //     	}
    //     }
    //     if (num>1) pts.push(path.getAt(num - 1));
    //     return pts;
	// },

	// /**
	//  * Convert EPSG 4326 to 900913
	//  * Return LatLng EPSG:900913
	//  */
	// degreesToMeters: function(latitude, longitude) {
	// 	var R = EARTH_RADIUS;
	// 	var x = R*Math.log(Math.tan(Math.PI/180 * (45 + latitude/2.0)));
	// 	var y = R*Math.PI / 180 * longitude;
	// 	return (new viettel.LatLng(x, y));
	// },

	// /**
	//  * Convert EPSG 900913 to 4326
	//  * Return LatLng EPSG:4326
	//  */
	// metersToDegrees: function(x, y) {
	// 	var R = EARTH_RADIUS;
	// 	var longitude = y/(R*Math.PI/180);
	// 	var latitude = (Math.atan(Math.pow(Math.E, (x / R)))/(Math.PI/180) - 45) * 2.0;
	// 	return new viettel.LatLng(latitude, longitude);
	// }
}

