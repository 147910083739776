// @flow
import type Map from './map';
import type Marker from './marker';
import {bindAll} from '../util/util';

export default class MarkerManager {
    _map: Map;
    _markers: Marker[];
    constructor(map: Map) {
        this._map = map;
        this._markers = [];

        bindAll([
            'show',
        ], this);
    }

    getMarker(lat:number, lng:number, zoom:number) {
        return this._markers.find(m => m.getLngLat().lat == lat && m.getLngLat().lng == lng && m.minZoom <= zoom && m.maxZoom >= zoom);
    }

    getMarkerCount(zoom: number) {
        return this._markers.filter(m => m.minZoom <= zoom && m.maxZoom >= zoom).length;
    }

    getAllMarkersByZoom(zoom: number) {
        if (!zoom) {
            zoom = this._map.getZoom();
        }
        return this._markers.filter(m => m.minZoom <= zoom && m.maxZoom >= zoom);
    }

    addMarkers(markers: [], minZoom = 10, maxZoom = 18) {
        markers.forEach(m => {
            m.minZoom = minZoom;
            m.maxZoom = maxZoom;
        })
        this._markers = this._markers.concat(markers);
    }

    addMarker(marker: Marker, minZoom = 10, maxZoom = 18) {
        marker.minZoom = minZoom;
        marker.maxZoom = maxZoom;
        this._markers.push(marker);
    }

    removeMarker(marker: Marker) {
        const index = this._markers.indexOf(marker);
        if (index > -1) {
            marker.remove();
            this._markers.splice(index, 1);
        }
    }

    clearMarkers() {
        this._markers.forEach(m => {
            m.remove();
        });
        this._markers = [];
        this._map.off('zoom', this.show);
    }

    refresh() {
        this.show();
        this._map.on('zoom', this.show);
    }

    show() {
        this._markers.forEach(m => {
            m.remove();
            if (this._map.getZoom() >= m.minZoom && this._map.getZoom() <= m.maxZoom) {
                m.addTo(this._map);
            }
        });
    }

    hide() {
        this._markers.forEach(m => {
            m.remove();
        })
    }

    toggle() {
        this._markers.forEach(m => {
            if (m._map) {
                m.remove();
            } else {
                if (this._map.getZoom() >= m.minZoom && this._map.getZoom() <= m.maxZoom) {
                    m.addTo(this._map);
                }
            }
        });
    }

    visible() {
        for (let m of this._markers) {
            if (!(m._map)) {
                return false;
            }
        }
        return true;
    }

    isHidden() {
        for (let m of this._markers) {
            if (m._map) {
                return false;
            }
        }
        return true;
    }
}
