// @flow
import {API_DOMAIN} from '../constants/config';
import VTUtil from "../util/vtutil";

export class AdministrativeService {

    static get LEVEL_TYPE() {
        return {
            "PROVINCE": 1,
            "DISTRICT": 2,
            "WARD": 3
        };
    }

    static get RETURN_TYPE() {
        return {
            "CODE": 1,
            "NAME": 2,
            "PATH": 4,
            "GEOM": 4,
            "INDEX_COLOR": 8,
            "OBJ_ID": 16,
            "BOUND": 32,
            "ALL": 255
        };
    }

    constructor({accessToken}) {
        this._accessToken = accessToken;
        this._adminServiceAPI = `${API_DOMAIN}placeapi/v2-old/place-api/VTMapService/administrationService`;
        //this._adminServiceAPI = 'https://api.viettelmaps.com.vn:8080/gateway/placeapi/v2/place-api/VTMapService/administrationService';
        //this._routingServiceAPI = 'http://viettelmap.vn/VTMapService/administrationService';
    }

    /**
     *
     * @param {string} coordinates Tọa độ các điểm giữa, định dạng: lat,lng;lat,lng;lat,lng...
     * @param {boolean} alternatives Cho phép trả về các phương án thay thế
     * @param {string} mode Phương tiện giao thông: driving, bycycling, walking
     */
    getFeatureInCircle({latLng, radius = false, rt, l, vtcallback}) {
        const options = [];
        options.push('f=' + 'circle');
        options.push(`pt=${latLng}`);
        options.push(`r=${radius}`);
        options.push(`rt=${rt}`);
        options.push(`l=${l}`);
        if (vtcallback) {
            options.push(`vtcallback=${vtcallback}`);
        }
        options.push(`k=${this._accessToken}`);
        //options.push('d=6e41.6e5e.6e52.6e43.6e43.6e52.6e5b.6e5a.6e56.6e47.6e19.6e41.6e59.');
        //options.push('v=2.0');

        //console.log('Fetching: ' + `${this._adminServiceAPI}?${options.join('&')}`);
        // eslint-disable-next-line no-undef
        return fetch(`${this._adminServiceAPI}?${options.join('&')}`)
            .then(response => response.json())
            .then(result => {
                result.items.forEach(item => {
                    item.paths = this._getPointInPolygon(item.paths);
                });
                return result;
            });
    }

    getFeatureFromCode({code, rt, l, vtcallback}) {
        const options = [];
        options.push('f=' + 'code');
        options.push(`code=${code}`);
        options.push(`rt=${rt}`);
        options.push(`l=${l}`);
        if (vtcallback) {
            options.push(`vtcallback=${vtcallback}`);
        }
        options.push(`k=${this._accessToken}`);
        //options.push('d=6e41.6e5e.6e52.6e43.6e43.6e52.6e5b.6e5a.6e56.6e47.6e19.6e41.6e59.');
        //options.push('v=2.0');

        //console.log('Fetching: ' + `${this._adminServiceAPI}?${options.join('&')}`);
        // eslint-disable-next-line no-undef
        return fetch(`${this._adminServiceAPI}?${options.join('&')}`)
            .then(response => response.json())
            .then(result => {
                result.items.forEach(item => {
                    item.paths = this._getPointInPolygon(item.paths);
                });
                return result;
            });
    }

    getFeatureFromLatLng({pt, rt, l, vtcallback}) {
        const options = [];
        options.push('f=' + 'point');
        options.push(`pt=${pt}`);
        options.push(`rt=${rt}`);
        options.push(`l=${l}`);
        if (vtcallback) {
            options.push(`vtcallback=${vtcallback}`);
        }
        options.push(`k=${this._accessToken}`);
        //options.push('d=6e41.6e5e.6e52.6e43.6e43.6e52.6e5b.6e5a.6e56.6e47.6e19.6e41.6e59.');
        //options.push('v=2.0');

        //console.log('Fetching: ' + `${this._adminServiceAPI}?${options.join('&')}`);
        // eslint-disable-next-line no-undef
        return fetch(`${this._adminServiceAPI}?${options.join('&')}`)
            .then(response => response.json())
            .then(result => {
                result.items.forEach(item => {
                    item.paths = this._getPointInPolygon(item.paths);
                });
                return result;
            });
    }

    _getPointInPolygon(pointHash) {
        return pointHash.map(hash => {
            const pointList = VTUtil.decryptPoints(hash);
            return pointList.map(p => p.toArray());
        });
    }
}
