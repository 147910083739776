// @flow

import { bindAll, extend } from '../util/util';
import type Map from '../ui/map';
import type { LngLatLike } from '../geo/lng_lat';
import LngLat from '../geo/lng_lat';
import type { PointLike } from '@mapbox/point-geometry';
import turf from '@turf/turf';
import { v4 as uuidv4 } from 'uuid';
import { Evented } from '../util/evented';

type Options = {
    path: LngLatLike[],
    strokeColor?: string,
    strokeOpacity?: number,
    strokeWeight?: number,
    iconUrl?: string,
    iconSize?: number,
    replay: boolean,
    velocity: number
};

const defaultOptions: Options = {
    path: [],
    strokeColor: '#007cbf',
    strokeOpacity: 1,
    strokeWeight: 2,
    iconSize: 1,
    replay: true,
    velocity: 1200
};

/**
 * Creates a marker component
 * @param {Object} [options]
 * @param {HTMLElement} [options.element] DOM element to use as a marker. The default is a light blue, droplet-shaped SVG marker.
 * @param {string} [options.anchor='center'] A string indicating the part of the AnimationPoints that should be positioned closest to the coordinate set via {@link AnimationPoints#setLngLat}.
 *   Options are `'center'`, `'top'`, `'bottom'`, `'left'`, `'right'`, `'top-left'`, `'top-right'`, `'bottom-left'`, and `'bottom-right'`.
 * @param {PointLike} [options.offset] The offset in pixels as a {@link PointLike} object to apply relative to the element's center. Negatives indicate left and up.
 * @param {string} [options.color='#3FB1CE'] The color to use for the default marker if options.element is not provided. The default is light blue.
 * @param {boolean} [options.draggable=false] A boolean indicating whether or not a marker is able to be dragged to a new position on the map.
 * @example
 * var marker = new vtmapgl.AnimationPoints()
 *   .setPath([30.5, 50.5])
 *   .addTo(map);
 * @see [Add custom icons with Markers](https://www.mapbox.com/mapbox-gl-js/example/custom-marker-icons/)
 * @see [Create a draggable AnimationPoints](https://www.mapbox.com/mapbox-gl-js/example/drag-a-marker/)
 */
export default class AnimationPoints extends Evented {
    _map: Map;
    options: Options
    _path: LngLatLike[];
    _mapLoadedInterval: number;
    _counter: number;
    _steps: number;
    ROUTE_SOURCE_ID: string;
    POINT_SOURCE_ID: string;
    ICON_IMAGE: string;
    _pointSource: GeoJSON;
    _routeSource: GeoJSON;
    _pointLayer: GeoJSON;
    _routeLayer: GeoJSON;
    _paused: boolean;
    _vertexAlongPath: number[];
    _nextTravelVertexIndex: number;

    constructor(options: Options) {
        this.options = extend(Object.create(defaultOptions), options);
        this._path = this.options.path;
        this._counter = 0;
        this._steps = 0;
        this.ROUTE_SOURCE_ID = 'ROUTE_' + uuidv4();
        this.POINT_SOURCE_ID = 'POINT_' + uuidv4();
        this.ICON_IMAGE = 'airport-15';
        this._paused = true;
        this._vertexAlongPath = [];
        this._nextTravelVertexIndex = 0;

        bindAll([
            '_connect',
            '_setup',
            '_generateStepsTwoPoints',
            '_animate',
            '_styleDataChange'
        ], this);
    }

    _connect() {
        this._map.off('load', this._connect);
        clearInterval(this._mapLoadedInterval);
        this._setup();
    }

    /**
     * Attaches the polygon to a map
     * @param {Map} map
     * @returns {Polygon} `this`
     */
    addTo(map: Map) {
        this.remove();
        this._map = map;

        if (map.loaded()) {
            this._connect();
        } else {
            map.on('load', this._connect);
            this._mapLoadedInterval = setInterval(() => {
                if (map.loaded()) this._connect();
            }, 16);
        }
        this._map.on('styledata', this._styleDataChange);

        return this;
    }

    /**
     * Removes the marker from a map
     * @example
     * var marker = new vtmapgl.AnimationPoints().addTo(map);
     * marker.remove();
     * @returns {AnimationPoints} `this`
     */
    remove() {
        if (this._map) {
            this._map.off('load', this._connect);
            clearInterval(this._mapLoadedInterval);

            this._map.off('styledata', this._styleDataChange);

            if (this._map.getLayer(this.POINT_SOURCE_ID)) {
                this._map.removeLayer(this.POINT_SOURCE_ID);
            }
            if (this._map.getLayer(this.ROUTE_SOURCE_ID)) {
                this._map.removeLayer(this.ROUTE_SOURCE_ID);
            }
            if (this._map.getSource(this.POINT_SOURCE_ID)) {
                this._map.removeSource(this.POINT_SOURCE_ID);
            }
            if (this._map.getSource(this.ROUTE_SOURCE_ID)) {
                this._map.removeSource(this.ROUTE_SOURCE_ID);
            }
            delete this._map;
        }

        return this;
    }

    _setup() {
        const route = {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        properties: {},
                        coordinates: []
                    }
                }
            ]
        };

        // A single point that animates along the route.
        // Coordinates are initially set to origin.
        const point = {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    properties: {},
                    geometry: {
                        type: 'Point',
                        properties: {},
                        coordinates: this._path[0]
                    }
                }
            ]
        };

        this._vertexAlongPath.push(0);
        const numPoints = this._path.length;
        let arc = [];
        for (let i = 0; i < numPoints - 1; i++) {
            const segment = this._generateStepsTwoPoints(this._path[i], this._path[i + 1], this.options.velocity);
            this._vertexAlongPath.push(arc.length + segment.length);
            arc = arc.concat(segment);
        }
        arc.push(this._path[numPoints - 1]);

        // Update the route with calculated arc coordinates
        route.features[0].geometry.coordinates = arc;

        const steps = arc.length - 1;

        // Used to increment the value of the point measurement against the route.
        const counter = 0;

        // Update point bearing
        point.features[0].properties.bearing = turf.bearing(
            turf.point(
                route.features[0].geometry.coordinates[0]
            ),
            turf.point(
                route.features[0].geometry.coordinates[1]
            )
        );

        this._map.addSource(this.ROUTE_SOURCE_ID, {
            'type': 'geojson',
            'data': route
        });

        this._map.addSource(this.POINT_SOURCE_ID, {
            'type': 'geojson',
            'data': point
        });

        const routeLayerGeojson = {
            'id': this.ROUTE_SOURCE_ID,
            'source': this.ROUTE_SOURCE_ID,
            'type': 'line',
            'layout': {
                'line-join': 'round',
                'line-cap': 'round'
            },
            'paint': {
                'line-width': this.options.strokeWeight,
                'line-color': this.options.strokeColor,
                'line-opacity': this.options.strokeOpacity
            }
        };

        this._map.addLayer(routeLayerGeojson);

        const pointLayerGeojson = {
            'id': this.POINT_SOURCE_ID,
            'source': this.POINT_SOURCE_ID,
            'type': 'symbol',
            'layout': {
                'icon-image': this.ICON_IMAGE,
                'icon-rotate': ['get', 'bearing'],
                'icon-rotation-alignment': 'map',
                'icon-allow-overlap': true,
                'icon-ignore-placement': true,
                'icon-size': this.options.iconSize
            }
        }

        if (this.options.iconUrl) {
            this._map.loadImage(this.options.iconUrl, (error, image) => {
                if (error) throw error;
                this.ICON_IMAGE = 'custom-icon-img-' + uuidv4();
                this._map.addImage(this.ICON_IMAGE, image);

                pointLayerGeojson.layout['icon-image'] = this.ICON_IMAGE;

                if (!this._map.getLayer(this.POINT_SOURCE_ID)) {
                    this._map.addLayer(pointLayerGeojson);
                }
            });
        } else {
            this._map.addLayer(pointLayerGeojson);
        }

        this._pointSource = point;
        this._routeSource = route;
        this._pointLayer = pointLayerGeojson;
        this._routeLayer = routeLayerGeojson;
        this._counter = counter;
        this._steps = steps;
    }

    _styleDataChange() {
        if (this._map.getLayer(this.POINT_SOURCE_ID)
            || this._map.getLayer(this.ROUTE_SOURCE_ID)
            || this._map.getSource(this.POINT_SOURCE_ID)
            || this._map.getSource(this.ROUTE_SOURCE_ID)
            || !this._routeSource
            || !this._pointSource
            || !this._routeLayer
            || !this._pointLayer) {
            return;
        }

        this._map.addSource(this.ROUTE_SOURCE_ID, {
            'type': 'geojson',
            'data': this._routeSource
        });

        this._map.addSource(this.POINT_SOURCE_ID, {
            'type': 'geojson',
            'data': this._pointSource
        });

        this._map.addLayer(this._routeLayer);

        if (this.options.iconUrl) {
            this._map.loadImage(this.options.iconUrl, (error, image) => {
                if (error) throw error;
                this.ICON_IMAGE = 'custom-icon-img-' + uuidv4();
                this._map.addImage(this.ICON_IMAGE, image);

                this._pointLayer.layout['icon-image'] = this.ICON_IMAGE;

                if (!this._map.getLayer(this.POINT_SOURCE_ID)) {
                    this._map.addLayer(this._pointLayer);
                }
            });
        } else {
            this._map.addLayer(this._pointLayer);
        }
    }

    _animate() {
        const newLngLatPoint = this._routeSource.features[0].geometry.coordinates[this._counter];
        // Update point geometry to a new position based on counter denoting
        // the index to access the arc.
        this._pointSource.features[0].geometry.coordinates = newLngLatPoint;

        // Calculate the bearing to ensure the icon is rotated to match the route arc
        // The bearing is calculate between the current point and the next point, except
        // at the end of the arc use the previous point and the current point
        this._pointSource.features[0].properties.bearing = turf.bearing(
            turf.point(
                this._routeSource.features[0].geometry.coordinates[
                this._counter >= this._steps ? this._counter - 1 : this._counter
                ]
            ),
            turf.point(
                this._routeSource.features[0].geometry.coordinates[
                this._counter >= this._steps ? this._counter : this._counter + 1
                ]
            )
        );

        // Check point position view
        if (!this._map.getBounds().contains(newLngLatPoint)) {
            this._map.flyTo({ center: newLngLatPoint });
        }

        // Update the source with this new data.
        this._map.getSource(this.POINT_SOURCE_ID) && this._map.getSource(this.POINT_SOURCE_ID).setData(this._pointSource);

        // Find whether counter drive on vertex
        const vertexIndex = this._vertexAlongPath[this._nextTravelVertexIndex];
        if (this._counter === vertexIndex) {
            this._nextTravelVertexIndex = (this._nextTravelVertexIndex + 1) % this._vertexAlongPath.length;
            const lngLat = this._routeSource.features[0].geometry.coordinates[vertexIndex];
            this.fire('vertex_pass', { lngLat })
        }

        this._counter += 1;
        // Request the next frame of animation so long the end has not been reached.
        if (this._paused) {
            if (this._counter > this._steps) {
                this._counter = 0;
            }
        } else {
            if (this._counter <= this._steps) {
                requestAnimationFrame(this._animate);
            } else {
                if (this.options.replay) {
                    this._counter = 0;
                    this._animate();
                }
            }
        }
    }

    pause() {
        this._paused = true;
    }

    start() {
        this._paused = false;
        this._animate();
    }

    toggleAnimate() {
        this._paused = !this._paused;
        if (!this._paused) {
            this.start();
        }
    }

    isStopped() {
        return this._paused;
    }

    getCurrentLngLat(): LngLat {
        const lngLat = this._pointSource.features[0].geometry.coordinates;
        return new LngLat(lngLat[0], lngLat[1]);
    }

    _generateStepsTwoPoints(origin: LngLatLike, destination: LngLatLike, velocity: number) {
        const geojson = {
            type: 'Feature',
            geometry: {
                type: 'LineString',
                properties: {},
                coordinates: [origin, destination]
            }
        };
        // Calculate the distance in kilometers between route start/end point.
        const lineDistance = turf.length(geojson, { units: 'meters' });

        const arc = [];

        // Number of steps to use in the arc and animation, more steps means
        // a smoother arc and animation, but too many steps will result in a
        // low frame rate
        // Estimate steps per second
        const stepPerSecond = 60;
        // velocity km/h => m/s
        const steps = (velocity * 1000 / 3600) / stepPerSecond;

        // Draw an arc between the `origin` & `destination` of the two points
        for (let i = 0; i < lineDistance; i += steps) {
            const segment = turf.along(geojson, i, { units: 'meters' });
            arc.push(segment.geometry.coordinates);
        }

        return arc;
    }
}
