import { API_DOMAIN } from '../constants/config';
import polyline from '@mapbox/polyline';

export class RoutingService {

  constructor({ accessToken }) {
    this._accessToken = accessToken;
    this._routingServiceAPI = API_DOMAIN + 'routing/';
  }

  /**
   *
   * @param {string} coordinates Tọa độ các điểm giữa, định dạng: lng,lat;lng,lat;lng,lat...
   * @param {boolean} alternatives Cho phép trả về các phương án thay thế
   * @param {string} mode Phương tiện giao thông: driving, bycycling, walking
   */
  fetchDirectionAsync({ coordinates, alternatives = false, mode = 'driving' }) {
      const options = [];
      options.push('coordinates=' + coordinates);
      options.push('alternatives=' + alternatives);
      options.push('steps=true');
      options.push('geometries=polyline');
      options.push('overview=full');
      options.push('continue_straight=true');
      options.push('access_token=' + this._accessToken);

      return fetch(`${this._routingServiceAPI}v2/directions/${mode}?${options.join('&')}`);
  }

  /**
   *
   * @param {string} coordinates Tọa độ các điểm giữa, định dạng: lng,lat;lng,lat;lng,lat...
   * @param {boolean} alternatives Cho phép trả về các phương án thay thế
   * @param {string} mode Phương tiện giao thông: driving, bycycling, walking
   */
  fetchDirections(coordinates, alternatives = false, mode = 'driving', vtcallback ) {
    const options = [];
    options.push('coordinates=' + coordinates);
    options.push('alternatives=' + alternatives);
    options.push('steps=true');
    options.push('geometries=polyline');
    options.push('overview=full');
    options.push('continue_straight=true');
    options.push('access_token=' + this._accessToken);

    fetch(`${this._routingServiceAPI}v2/directions/${mode}?${options.join('&')}`).then(result => {
      result.json().then(data => {
        if (data.code != 'Ok') {
            vtcallback(data, data.statusCode);
            return;
        }
        const num = data.routes.length;

        for (var i=0; i<num; i++) {
          let finalRoute = polyline.decode(data.routes[i].geometry, 5).map(c => c.reverse());
          data.routes[i].geometry = finalRoute == null ? data.routes[i].geometry : finalRoute;
        }

        vtcallback(data, data.code);
      });
    });
  }
}
