// @flow
import {bindAll, extend} from '../util/util';
import {Evented} from '../util/evented';
import type Map from './map';
import type {LngLatLike} from "../geo/lng_lat";
import LngLat from '../geo/lng_lat';
import {v4 as uuidv4} from 'uuid';
import { MapMouseEvent, MapTouchEvent } from './events';
import Marker from './marker';
import DOM from '../util/dom';
import turf from '@turf/turf';
import PolylineDraw from './polyline_draw';
import LngLatBounds from '../geo/lng_lat_bounds';
import {EDIT_FEATURE_MARKER_IMG_URL, EDIT_FEATURE_MIDDLE_MARKER_IMG_URL} from '../constants/config';

const MARKER_TYPE = {
    VERTEX: 'VERTEX',
    MIDPOINT: 'MIDPOINT'
}

type MarkerInfo = {
    type: string
}

type Options = {
    path: LngLatLike[],
    strokeColor: string,
    strokeOpacity: number,
    strokeWeight: number,
    strokeDashstyle: string;
    zIndex: number,
    clickable: boolean,
    draggable: boolean,
    editable: boolean,
    visible: boolean
};

const defaultOptions: Options = {
    path: [],
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    strokeDashstyle: '',
    zIndex: null,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true
};

const MAPPING_LAYER_PROPERTY: Map<string, string> = {
    strokeColor: 'line-color',
    strokeOpacity: 'line-opacity',
    strokeWeight: 'line-width'
};

/**
 * Creates a polyline component
 * @param {Object} [options]
 * @param {HTMLElement} [options.element] DOM element to use as a polyline. The default is a light blue, droplet-shaped SVG polyline.
 * @param {string} [options.anchor='center'] A string indicating the part of the Polyline that should be positioned closest to the coordinate set via {@link Polyline#setLngLat}.
 *   Options are `'center'`, `'top'`, `'bottom'`, `'left'`, `'right'`, `'top-left'`, `'top-right'`, `'bottom-left'`, and `'bottom-right'`.
 * @param {PointLike} [options.offset] The offset in pixels as a {@link PointLike} object to apply relative to the element's center. Negatives indicate left and up.
 * @param {string} [options.color='#3FB1CE'] The color to use for the default polyline if options.element is not provided. The default is light blue.
 * @param {boolean} [options.draggable=false] A boolean indicating whether or not a polyline is able to be dragged to a new position on the map.
 * @example
 * var polyline = new mapboxgl.Polyline()
 *   .setLngLat([30.5, 50.5])
 *   .addTo(map);
 * @see [Add custom icons with Markers](https://www.mapbox.com/mapbox-gl-js/example/custom-polyline-icons/)
 * @see [Create a draggable Polyline](https://www.mapbox.com/mapbox-gl-js/example/drag-a-polyline/)
 */
export default class Polyline extends Evented {
    _map: Map;
    _draggable: boolean;
    _clickable: boolean;
    options: Options;
    _canvas: HTMLElement;
    SOURCE_ID: string;
    _mapLoadedInterval: number;
    _vertexMarkers: Marker[];
    _midpointMarkers: Marker[];
    _polylineDraw: PolylineDraw;
    isDrawing: boolean;
    _geojson: GeoJSON;
    _mouseDownLngLat: LngLat;
    _isMarkerDrag: boolean;
    _tempPolyline: Polyline;

    constructor(options?: Options) {
        super();
        this.options = extend({}, defaultOptions, options);
        this._draggable = this.options.draggable;
        this._clickable = this.options.clickable;
        this.SOURCE_ID = `POLYLINE_${uuidv4()}`;
        this._geojson = {
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: this.options.path
            }
        };
        this._mapLoadedInterval = null;
        this._vertexMarkers = [];
        this._midpointMarkers = [];
        this.isDrawing = false;
        this._isMarkerDrag = false;

        bindAll([
            '_addSource',
            '_connect',
            '_onMouseEnter',
            '_onMouseLeave',
            '_onMarkerDragend',
            '_onMarkerDrag',
            '_onMouseDown',
            '_onMouseUp',
            '_onMouseMove',
            '_onTouchStart',
            '_onClickLine',
            '_onMarkerMouseDown',
            '_onMarkerMouseUp',
            '_clearUpMarker'
        ], this);
    }

    _connect() {
        this._map.off('load', this._connect);
        clearInterval(this._mapLoadedInterval);
        this._addSource();
        this.setEditable(this.options.editable);
        this.setDraggable(this.options.draggable);
        this.setClickable(this.options.clickable);
    }

    /**
     * Attaches the polyline to a map
     * @param {Map} map
     * @returns {Polyline} `this`
     */
    addTo(map: Map) {
        this.remove();
        this._map = map;
        this._canvas = map.getCanvasContainer();

        if (map.loaded()) {
            this._connect();
        } else {
            map.on('load', this._connect);
            this._mapLoadedInterval = setInterval(() => {
                if (map.loaded()) this._connect();
            }, 16);
        }

        return this;
    }

    /**
     * Removes the polyline from a map
     * @example
     * var polyline = new vtmapgl.Polyline().addTo(map);
     * polyline.remove();
     * @returns {Polyline} `this`
     */
    remove() {
        if (this._map) {
            this._map.off('load', this._connect);
            clearInterval(this._mapLoadedInterval);
            this.setEditable(false);
            this.setDraggable(false);
            this.setClickable(false);

            if (this._map.getLayer(this.SOURCE_ID)) {
                this._map.removeLayer(this.SOURCE_ID);
            }
            if (this._map.getSource(this.SOURCE_ID)) {
                this._map.removeSource(this.SOURCE_ID);
            }
            delete this._map;
        }
        return this;
    }

    _addSource() {
        this._map.addSource(this.SOURCE_ID, {
            type: 'geojson',
            data: this._geojson
        });
        this._map.addLayer({
            id: this.SOURCE_ID,
            type: 'line',
            source: this.SOURCE_ID,
            layout: {
                'visibility': this.options.visible ? 'visible' : 'none',
                'line-join': 'round',
                'line-cap': 'round'
            },
            paint: {
                'line-color': this.options.strokeColor,
                'line-width': this.options.strokeWeight,
                'line-opacity': this.options.strokeOpacity,
                'line-dasharray': this.options.strokeDashstyle === 'dashdot' ? [0.2, 2] : [1]
            }
        });
    }

    _onMouseEnter(e: MapMouseEvent | MapTouchEvent) {
        this._map.getCanvas().style.cursor = 'pointer';
    }

    _onMouseLeave(e: MapMouseEvent | MapTouchEvent) {
        this._map.getCanvas().style.cursor = '';
    }

    setPaints(options: any) {
        if (!this._map.getLayer(this.SOURCE_ID)) {
            return;
        }
        const possibleProperties = Object.keys(MAPPING_LAYER_PROPERTY);
        Object.entries(options).forEach(([key, value]) => {
            if (!possibleProperties.includes(key)) return;
            const layerProperty = MAPPING_LAYER_PROPERTY[key];
            this._map.setPaintProperty(this.SOURCE_ID, layerProperty, value);
        });
    }

    setPath(path: LngLatLike[]) {
        if (this._map) {
            this._geojson.geometry.coordinates = path;
            const source = this._map.getSource(this.SOURCE_ID);
            if (source) {
                source.setData(this._geojson);
            }
        }
    }

    getPath(): LngLatLike[] {
        return this._geojson.geometry.coordinates.map(lngLat => ([...lngLat]));
    }

    setEditable(editable: boolean) {
        this.options.editable = editable;
        if (this._map) {
            this.removeMarkers();
            if (this.options.editable) {
                // Add markers
                this.initMarkers();
            }
        }
        return this;
    }

    initMarkers() {
        const path = this.getPath();
        const pathLength = path.length;
        for (let i = 0; i < pathLength; i++) {
            const firstPoint = path[i];
            // Add feature edit marker
            this._vertexMarkers.push(this.insertVertexMarker(firstPoint));
            if (i < pathLength - 1) {
                // Add feature middle marker
                const secondPoint = path[i + 1];
                const midpoint = this._getMidpointBetween(firstPoint, secondPoint);
                this._midpointMarkers.push(this.insertMidpointMarker(midpoint));
            }
        }
    }

    insertVertexMarker(lngLat: LngLatLike) {
        const element = this._createMarkerElement(EDIT_FEATURE_MARKER_IMG_URL);
		const marker = new Marker({
            draggable: true,
            element
        }).setLngLat(lngLat)
        .addTo(this._map);
        const markerInfo: MarkerInfo = {
            type: MARKER_TYPE.VERTEX
        }
        marker.markerInfo = markerInfo;
        element.addEventListener('mousedown', this._onMarkerMouseDown);
        element.addEventListener('mouseup', this._onMarkerMouseUp);
        marker.on('drag', this._onMarkerDrag);
        marker.on('dragend', this._onMarkerDragend)
        return marker;
    }

    insertMidpointMarker(lngLat: LngLatLike) {
        // Get middle point
        const element = this._createMarkerElement(EDIT_FEATURE_MIDDLE_MARKER_IMG_URL);
		const marker = new Marker({
            draggable: true,
            element
        }).setLngLat(lngLat)
        .addTo(this._map);
        const markerInfo: MarkerInfo = {
            type: MARKER_TYPE.MIDPOINT
        }
        marker.markerInfo = markerInfo;
        element.addEventListener('mousedown', this._onMarkerMouseDown);
        element.addEventListener('mouseup', this._onMarkerMouseUp);
        marker.on('drag', this._onMarkerDrag);
        marker.on('dragend', this._onMarkerDragend);
        return marker;
    }
    _onMarkerMouseDown(e: MapMouseEvent | MapTouchEvent) {
        this._isMarkerDrag = true;
    }
    _onMarkerMouseUp(e: MapMouseEvent | MapTouchEvent) {
        this._isMarkerDrag = false;
    }
    _createMarkerElement(imgUrl: string): HTMLElement {
        const element = DOM.create('div')
        element.innerHTML = `<img src=${imgUrl} alt="middle-marker" style="width:12px;height:12px;">`;
        element.style.cursor = 'pointer';
        element.style.zIndex = 5;
        return element;
    }
    removeMarkers() {
        this._vertexMarkers.forEach(this._clearUpMarker);
        this._vertexMarkers = [];
        this._midpointMarkers.forEach(this._clearUpMarker);
        this._midpointMarkers = [];
    }
    _clearUpMarker(marker: Marker) {
        marker.getElement().removeEventListener('mousedown', this._onMarkerMouseDown);
        marker.getElement().removeEventListener('mouseup', this._onMarkerMouseUp);
        marker.off('drag', this._onMarkerDrag);
        marker.off('dragend', this._onMarkerDragend);
        marker.remove();
    }
    _onMarkerDrag(e: any) {
        const marker: Marker = e.target;
        const markerLnglat = marker.getLngLat().toArray();
        let tempPolylinePath;
        let index;
        if (this.isVertex(marker)) {
            tempPolylinePath = [markerLnglat];
            index = this._vertexMarkers.indexOf(marker);
            const beforeVertexIndex = index - 1;
            const afterVertexIndex = index + 1;
            if (beforeVertexIndex >= 0) {
                tempPolylinePath.unshift(this._vertexMarkers[beforeVertexIndex].getLngLat().toArray());
            }
            if (afterVertexIndex < this._vertexMarkers.length) {
                tempPolylinePath.push(this._vertexMarkers[afterVertexIndex].getLngLat().toArray());
            }
        } else {
            index = this._midpointMarkers.indexOf(marker);
            // Create polyline from vertex index, current midpoint, next vertex index
            const beforeVertexLngLat = this._vertexMarkers[index].getLngLat().toArray();
            const afterVertexLngLat = this._vertexMarkers[index + 1].getLngLat().toArray();
            tempPolylinePath = [beforeVertexLngLat, markerLnglat, afterVertexLngLat];
        }
        this._createTempPolyline(tempPolylinePath);
    }

    _onMarkerDragend(e: any) {
        this._tempPolyline.setVisible(false);
        const marker: Marker = e.target;
        const markerLnglat = marker.getLngLat().toArray();
        let path = this.getPath();
        let index;
        if (this.isVertex(marker)) {
            index = this._vertexMarkers.indexOf(marker);
            path[index] = markerLnglat;
            // Update adjacent midpoints
            const beforeVertexMidpointIndex = index - 1;
            const afterVertexMidpointIndex = index;
            if (beforeVertexMidpointIndex >= 0) {
                this._midpointMarkers[beforeVertexMidpointIndex].setLngLat(this._getMidpointBetween(markerLnglat, this._vertexMarkers[index - 1].getLngLat().toArray()));
            }
            if (afterVertexMidpointIndex < this._midpointMarkers.length) {
                this._midpointMarkers[afterVertexMidpointIndex].setLngLat(this._getMidpointBetween(markerLnglat, this._vertexMarkers[index + 1].getLngLat().toArray()));
            }
        } else {
            index = this._midpointMarkers.indexOf(marker);
            // Add new point to path
            path.splice(index + 1, 0, markerLnglat);
            // Add 2 new midpoints
            const fistNewMidpointMarker = this.insertMidpointMarker(this._getMidpointBetween(markerLnglat, this._vertexMarkers[index].getLngLat().toArray()));
            const secondNewMidpointMarker = this.insertMidpointMarker(this._getMidpointBetween(markerLnglat, this._vertexMarkers[index + 1].getLngLat().toArray()));
            const removeMidpoints = this._midpointMarkers.splice(index, 1, fistNewMidpointMarker, secondNewMidpointMarker);
            removeMidpoints.forEach(midpoint => midpoint.remove());
            // Add new vertex marker
            const newVertexMarker = this.insertVertexMarker(markerLnglat);
            this._vertexMarkers.splice(index + 1, 0, newVertexMarker);
        }
        this.setPath(path);
        this.fire('endEdit');
        this._isMarkerDrag = false;
    }

    setDrawing(drawing: boolean) {
        if (drawing) {
            if (!this._polylineDraw) {
                this._polylineDraw = new PolylineDraw({
                    polyline: this
                });
                this._polylineDraw.activate();
            }
        } else {
            if (this._polylineDraw) {
                this._polylineDraw.deactivate();
                this._polylineDraw = null;
                this.fire('endDrawing');
            }
        }
        this.isDrawing = drawing;
    }

    getOptions() {
        return this.options;
    }

    getMap() {
        return this._map;
    }

    setDraggable(draggable: boolean) {
        this.options.draggable = draggable;
        if (this._map) {
            if (this.options.draggable) {
                if (!this.options.clickable) {
                    this._map.on('mouseenter', this.SOURCE_ID, this._onMouseEnter);
                    // Change it back to a pointer when it leaves.
                    this._map.on('mouseleave', this.SOURCE_ID, this._onMouseLeave);
                }
                this._map.on('mousedown', this.SOURCE_ID, this._onMouseDown);
                this._map.on('touchstart', this.SOURCE_ID, this._onTouchStart);

            } else {
                if (!this.options.clickable) {
                    this._map.off('mouseenter', this.SOURCE_ID, this._onMouseEnter);
                    this._map.off('mouseleave', this.SOURCE_ID, this._onMouseLeave);
                }
                this._map.off('mousedown', this.SOURCE_ID, this._onMouseDown);
                this._map.off('touchstart', this.SOURCE_ID, this._onTouchStart);
            }
        }
        return this;
    }

    _onMouseDown(e: MapMouseEvent) {
        e.preventDefault();
        if (this._isMarkerDrag) {
            return;
        }
        this._mouseDownLngLat = e.lngLat;
        this._map.on('mousemove', this._onMouseMove);
        this._map.once('mouseup', this._onMouseUp);
    }

    _onTouchStart(e: MapTouchEvent) {
        e.preventDefault();
        if (this._isMarkerDrag) {
            return;
        }
        this._mouseDownLngLat = e.lngLat;
        this._map.on('touchmove', this._onMouseMove);
        this._map.once('touchend', this._onMouseUp);
    }

    _updateMarkers(path: LngLatLike[]) {
        const pathLength = path.length;
        for (let i = 0; i < pathLength; i++) {
            const currentPoint = path[i];
            // Update markers position
            this._vertexMarkers[i].setLngLat(currentPoint).addTo(this._map);
            // Update middle markers position
            if (i < pathLength - 1) {
                const pointAfter = path[i + 1];
                const midPoint = this._getMidpointBetween(currentPoint, pointAfter);
                this._midpointMarkers[i].setLngLat(midPoint).addTo(this._map);
            }
        }
    }

    _getMidpointBetween(firstPoint: LngLatLike, secondPoint: LngLatLike): LngLatLike {
        return turf.midpoint(turf.point(firstPoint), turf.point(secondPoint)).geometry.coordinates;
    }

    _onMouseMove(e: MapMouseEvent | MapTouchEvent) {
        // Create transformPolyline geojson
        const transformPolyline = this._generateTransformPolyline(e.lngLat);
        const path = transformPolyline.geometry.coordinates;
        this._updateMarkers(path);
        this._map.getSource(this.SOURCE_ID).setData(transformPolyline);
        this.fire('drag', e);
    }

    _onMouseUp(e: MapMouseEvent | MapTouchEvent) {
        this._geojson = this._generateTransformPolyline(e.lngLat);
        this._map.off('mousemove', this._onMouseMove);
        this._map.off('touchmove', this._onMouseMove);
        this.fire('dragend', e);
    }

    _generateTransformPolyline(desDirectPoint: LngLat): GeoJSON {
        const sourceDirectionPoint = turf.point(this._mouseDownLngLat.toArray());
        const destinationDirectionPoint = turf.point(desDirectPoint.toArray());
        const distance = turf.distance(sourceDirectionPoint, destinationDirectionPoint);
        const bearing = turf.bearing(sourceDirectionPoint, destinationDirectionPoint);

        let path = this.getPath();
        path = path.map(lngLat => this._calculateDestinationLngLat(LngLat.convert(lngLat), distance, bearing));
        const transformPolyline = {
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: path
            }
        };
        return transformPolyline;
    }

    _calculateDestinationLngLat(srcPoint: LngLat, distance: number, bearing: number): LngLatLike {
        const sourcePoint = turf.point(srcPoint.toArray());
        const destinationPoint = turf.destination(sourcePoint, distance, bearing);
        return destinationPoint.geometry.coordinates;
    }

    _calculateTranslateGeojson(source: LngLat, destination: LngLat): GeoJSON {
        const mouseDownPoint = turf.point(source.toArray());
        const mouseMovePoint = turf.point(destination.toArray());
        const distance = turf.distance(mouseDownPoint, mouseMovePoint);
        const direction = turf.bearing(mouseDownPoint, mouseMovePoint);
        return turf.transformTranslate(this._geojson, distance, direction);
    }

    _onClickLine(e: MapMouseEvent | MapTouchEvent) {
        e.preventDefault();
        this.fire('click', e);
    }

    setClickable(clickable: boolean) {
        this.options.clickable = clickable;
        if (this._map) {
            if (this.options.clickable) {
                // Change the cursor to a pointer when the mouse is over the places layer.
                this._map.on('mouseenter', this.SOURCE_ID, this._onMouseEnter);
                // Change it back to a pointer when it leaves.
                this._map.on('mouseleave', this.SOURCE_ID, this._onMouseLeave);
                this._map.on('click', this.SOURCE_ID, this._onClickLine);
            } else {
                if (!this.options.draggable) {
                    this._map.off('mouseenter', this.SOURCE_ID, this._onMouseEnter);
                    // Change it back to a pointer when it leaves.
                    this._map.off('mouseleave', this.SOURCE_ID, this._onMouseLeave);
                }
                this._map.off('click', this.SOURCE_ID, this._onClickLine);
            }
        }
        return this;
    }

    _createTempPolyline(path: LngLatLike[]) {
        if (!this._tempPolyline) {
            this._tempPolyline = new Polyline({
                path,
                strokeColor: this.getOptions().strokeColor,
                strokeWeight: this.getOptions().strokeWeight,
                strokeOpacity: 0.3
            }).addTo(this._map);
        } else {
            this._tempPolyline.setPath(path);
            this._tempPolyline.setVisible(true);
        }
    }

    isVertex(marker: Marker): boolean {
        return marker && marker.markerInfo && marker.markerInfo.type === MARKER_TYPE.VERTEX;
    }

    setVisible(visible: boolean) {
        this.options.visible = !!visible;
        this._map.setLayoutProperty(this.SOURCE_ID, 'visibility', this.options.visible ? 'visible' : 'none')
    }

    getLength() {
        return turf.length(this._geojson, { units: 'meters' });
    }

    getBounds() {
        const bounds = new LngLatBounds();
        this.getPath().forEach(coordinate => {
            bounds.extend(coordinate);
        });
        return bounds;
    }
}
