import LngLat from '../geo/lng_lat';

/**
 * Lop chua ham tien ich su dung trong ban do
 */
const VTUtil = {

    throwVTError:function(message) {
        throw (new Error(message));
    },
    isIEBrowser: function(){
        return (this.BROWSER_NAME == "msie")
    },
    standardizeEvent: function(evt){
        if(this.isIEBrowser()){
            this.standardizeIEEvent(evt);
        }
    }
    ,
    standardizeIEEvent: function(evt){
        var e = document.documentElement, b = document.body;
        if(evt.pageX == null && evt.pageY == null){
            evt.pageX = evt.clientX + (e && e.scrollLeft || b.scrollLeft ||0);
            evt.pageY = evt.clientY + (e && e.scrollTop || b.scrollTop ||0);
        }


        if(evt.button == 1){
            evt.which = 1;
        }
        else if(evt.button == 2){
            evt.which = 3;
        }
        else if(evt.button == 4){
            evt.which = 2;
        }
    }
    ,
    getBrowserName:function() {
        var name = "";
        var userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf("opera")!=-1) {
            name = "opera";
        } else {
            if (userAgent.indexOf("msie")!=-1) {
                name = "msie";
            } else {
                if (userAgent.indexOf("safari")!=-1) {
                    name = "safari";
                } else {
                    if (userAgent.indexOf("mozilla")!=-1) {
                        if (userAgent.indexOf("firefox")!=-1) {
                            name = "firefox";
                        } else {
                            name = "mozilla";
                        }
                    }
                }
            }
        }
        return name;
    },

    getObjectFromID:function(id) {
        var obj = null;
        if (document.layers) {
            obj = document.layers[id];
        } else if (document.all) {
            obj = document.all[id];
        } else if (document.getElementById) {
            obj = document.getElementById(id);
        }
        return obj;
    },

    setDivContent:function(objDiv, content) {
        if (VTUtil.isObject(objDiv)) {
            objDiv.innerHTML = content;
        }
    },

    min:function(a, b) {
        return (a>b?b:a);
    },

    max:function(a, b) {
        return (a>b?a:b);
    },

    replaceAll:function(data, sFind, sReplace) {
        if (data == null)
			return data;
		var strText = data;
        var intIndexOfMatch = strText.indexOf(sFind);

        // Keep looping while an instance of the target string
        // still exists in the string.
        while (intIndexOfMatch != -1) {
            // Relace out the current instance.
            strText = strText.replace(sFind, sReplace);

            // Get the index of any next matching substring.
            intIndexOfMatch = strText.indexOf(sFind);
        }

        // Return the updated string with ALL the target strings
        // replaced out with the new substring.
        return( strText );
    },

    removeVnAccent:function(text) {
        var data = text;
        var sFind       = "\u0105\u00E0\u00E1\u1EA3\u00E3\u1EA1\u00E4\u00E5\u0101\u0104\u00C0\u00C1\u1EA2\u00C3\u1EA0\u00C4\u00C5\u0100\u00E2\u1EA7\u1EA5\u1EA9\u1EAB\u1EAD\u00C2\u1EA6\u1EA4\u1EA8\u1EAA\u1EAC\u0103\u1EB1\u1EAF\u1EB3\u1EB5\u1EB7\u0102\u1EB0\u1EAE\u1EB2\u1EB4\u1EB6\u0119\u00E8\u00E9\u1EBB\u1EBD\u1EB9\u00EB\u0113\u0115\u0117\u011B\u0118\u00C8\u00C9\u1EBA\u1EBC\u1EB8\u0112\u0114\u0116\u011A\u00EA\u1EC1\u1EBF\u1EC3\u1EC5\u1EC7\u00CA\u1EC0\u1EBE\u1EC2\u1EC4\u1EC6\u00EC\u00ED\u1EC9\u0129\u1ECB\u00EE\u00EF\u0129\u012B\u012D\u00CC\u00CD\u1EC8\u0128\u1ECA\u00CE\u00CF\u0128\u012A\u012C\u00F2\u00F3\u1ECF\u00F5\u1ECD\u00F6\u014D\u014F\u0151\u00D2\u00D3\u1ECE\u00D5\u1ECC\u00D6\u014C\u014E\u0150\u00F4\u1ED3\u1ED1\u1ED5\u1ED7\u1ED9\u00D4\u1ED2\u1ED0\u1ED4\u1ED6\u1ED8\u01A1\u1EDD\u1EDB\u1EDF\u1EE1\u1EE3\u01A0\u1EDC\u1EDA\u1EDE\u1EE0\u1EE2\u00F9\u00FA\u1EE7\u0169\u1EE5\u00FB\u00FC\u016B\u016D\u016F\u00D9\u00DA\u1EE6\u0168\u1EE4\u00DB\u00DC\u016A\u016C\u016E\u01B0\u1EEB\u1EE9\u1EED\u1EEF\u1EF1\u01AF\u1EEA\u1EE8\u1EEC\u1EEE\u1EF0\u0111\u0110\u1EF3\u00FD\u1EF7\u1EF9\u1EF5\u1EF2\u00DD\u1EF6\u1EF8\u1EF4";
        var sReplace    = "aaaaaaaaaAAAAAAAAAaaaaaaAAAAAAaaaaaaAAAAAAeeeeeeeeeeeEEEEEEEEEEeeeeeeEEEEEEiiiiiiiiiiIIIIIIIIIIoooooooooOOOOOOOOOooooooOOOOOOooooooOOOOOOuuuuuuuuuuUUUUUUUUUUuuuuuuUUUUUUdDyyyyyYYYYY";
        var length = sReplace.length;
        for (var i=0; i<length; i++)
            data = VTUtil.replaceAll(data, sFind[i], sReplace[i]);
        return data;
    },

    randomNumber:function(start, end) {
        return (start + Math.floor(Math.random()*(end-start)));
    },

    randomString:function(length) {
        var base = "qwertyuiopasdfghjklzxcvbnm";
        var baseLength = base.length;
        var ret = "";
        for (var i=0; i<length; i++) {
            ret += base.charAt(VTUtil.randomNumber(0, baseLength-1));
        }

        return ret;
    },

    getTimeStamp:function() {
        var timestamp = new Date();
        return timestamp.getTime();
    },

    loadJSFile:function(filename) {
		if (filename == null) {
			return;
		}
        var eJS = document.createElement('script');
        eJS.setAttribute("type", "text/javascript");
        eJS.setAttribute("src", filename);
        document.getElementsByTagName("head")[0].appendChild(eJS);
    },

    loadCSSFile:function(filename) {
		if (filename == null) {
			return;
		}
        var eCSS = document.createElement("link");
        eCSS.setAttribute("rel", "stylesheet");
        eCSS.setAttribute("type", "text/css");
        eCSS.setAttribute("href", filename);
        document.getElementsByTagName("head")[0].appendChild(eCSS);
    },

    disableBrowserContextMenu:function(divNode) {
        divNode.oncontextmenu = function(e) {
            e = e?e:window.event;
            if (e.preventDefault) {
                return e.preventDefault(); // For non-IE browsers.
            }
            else
                return false; // For IE browsers.
        };
    },

    loadURL:function(url, funcName) {
        if (url.indexOf("?")>-1){
            url += "&vtcallback=";
        } else {
            url += "?vtcallback=";
        }
        url += funcName;
        url += "&k=" + key;
        url += "&d=" + VTUtil.encodeString(window.location.host);
        url += "&v=" + MAP_DATA_VERSION;
        var a = document.createElement("script");
        a.setAttribute("src", url);
        a.setAttribute("type","text/javascript");
        document.body.appendChild(a);
		setTimeout(function(){
			document.body.removeChild(a);
		}, 5000);
    },

    getImageURL:function(id, isAvatar) {
        var url = urlServer;
        url += imageService;
        url += "?id=" + id;
        url += "&fullimage=" + (isAvatar?"false":"true");
        url += "&k=" + key;
        url += "&d=" + VTUtil.encodeString(window.location.host);
        return url;
    },

    roundNumber:function(number, precision) {
        if (!VTObjChecker.isNumber(precision) || precision<0)
            precision = 6;
        var fixValue = parseFloat(Math.pow(10, precision));
        var retValue = parseInt(Math.round(number * fixValue)) / fixValue;
        return retValue;
    },

    getDistanceStr:function(distance) {
        var str = "";
        if (distance>=10000) {
            str = VTUtil.roundNumber(distance/1000, 1);
            str += " km";
        }
        else if (distance>=1000) {
            str = VTUtil.roundNumber(distance/1000, 2);
            str += " km";
        }
        else {
            str = VTUtil.roundNumber(distance, 0);
            str += " m";
        }
        return str;
    },

    parseInteger:function(value) {
        return parseInt(value);
    },

    parseDouble:function(value){
        if (isNaN(parseInt(value)))
            return NaN;
        return (value*1.0);
    },

    parseLatLon:function(latlon) {
        if (VTObjChecker.isStringEmpty(latlon))
            return null;

        var arrLatLon = latlon.split(",");
        if (VTUtil.isObject(arrLatLon) && arrLatLon.length==2) {
            var lat = VTUtil.parseDouble(arrLatLon[0]);
            var lon = VTUtil.parseDouble(arrLatLon[1]);
            if (lat!=0 && lon!=0){
                return new VTLatLng(lat, lon);
            }
        }

        return null;
    },

    getHeightOfDiv:function(width, htmlContent) {
        var tagDiv = document.createElement('div');
        tagDiv.style.cssText = 'display: block; height: auto; width: ' + width + 'px;';
        tagDiv.innerHTML = htmlContent;
        document.body.appendChild(tagDiv);
        var height = tagDiv.clientHeight;
        document.body.removeChild(tagDiv);
        return height;
    },

    encodeString:function(plainText) {
        var encryptedText = "";
        if (plainText!=null) {
            encryptedText = VTHexaConversion.stringToHex(plainText);
            if (encryptedText!=null) {
                encryptedText = VTUtil.replaceAll(encryptedText, ' ', '.');
            }
        }
        return encryptedText;
    },
    decodePoints:function(encoded, precision){
        precision = Math.pow(10, -precision);
        var len = encoded.length, index=0, lat=0, lng = 0, array = [];
        while (index < len) {
            var b, shift = 0, result = 0;
            do {
                b = encoded.charCodeAt(index++) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);
            var dlat = ((result & 1) ? ~(result >> 1) : (result >> 1));
            lat += dlat;
            shift = 0;
            result = 0;
            do {
                b = encoded.charCodeAt(index++) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);
            var dlng = ((result & 1) ? ~(result >> 1) : (result >> 1));
            lng += dlng;
            //array.push([lat * precision, lng * precision]);
            array.push(new LngLat(lng * precision, lat * precision));
        }
        return array;
    },
    // Gia nen xau o che do ENCRYPT_DISTANCE_POINT
    decryptPoints: function(encodedString) {
        if (encodedString==null) return null;
        var precision = 1/1E6;			//FLOOR_VALUE = 1E6;
        var len = encodedString.length;
        var index = 0;
        if (len<=0) return null;

        var points = new Array();
        var b, shift, result;
        var lat, lng;

        // Tinh gia tri diem dau
        while (index < len) {
            // Tinh latitude
            shift = 0;
            result = 0;
            do {
                b = encodedString.charCodeAt(index++) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);
            if ((result & 0x01)!=0)
                lat = ~(result >> 1);
            else
                lat = (result >> 1);

            // Tinh longitude
            shift = 0;
            result = 0;
            do {
                b = encodedString.charCodeAt(index++) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);
            if ((result & 1)!=0)
                lng = ~(result >> 1);
            else
                lng = (result >> 1);
            const pt = new LngLat(lng * precision, lat * precision);
            points.push(pt);
        }
        return points;
    },
    decryptPoint:function(encodedString) {
        if (encodedString==null) return null;
        var precision = 1/1E6;			//FLOOR_VALUE = 1E6;
        var len = encodedString.length;
        var index = 0;
        if (len<=0) return null;

        var points = new Array();
        var b, shift, result;
        var lat, lng;

        // Tinh gia tri diem dau
        let pt = null;
        if (index < len) {
            // Tinh latitude
            shift = 0;
            result = 0;
            do {
                b = encodedString.charCodeAt(index++) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);
            if ((result & 0x01)!=0)
                lat = ~(result >> 1);
            else
                lat = (result >> 1);

            // Tinh longitude
            shift = 0;
            result = 0;
            do {
                b = encodedString.charCodeAt(index++) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);
            if ((result & 1)!=0)
                lng = ~(result >> 1);
            else
                lng = (result >> 1);
            pt = new LngLat(lng * precision, lat * precision);
        }
        return pt;
    },

    objectToString: function(o) {
		var parse = function(_o) {
			var a = [], t;
			var isIE = VTUtil.isIEBrowser();
			for(var p in _o) {
			var hasOwnProp = false;
			if (isIE)
				hasOwnProp = Object.prototype.hasOwnProperty.call(_o, p);
			else
				hasOwnProp = _o.hasOwnProperty(p);
			if (hasOwnProp) {
				t = _o[p];
				if (t==undefined)
					a[a.length] = [ p + ": undefined"];
				else if (t==null)
					a[a.length] = [ p + ": null"];
				else if (t==NaN)
					a[a.length] = [ p + ": NaN"];
				else if (typeof t == "string")
					a[a.length] = [ p+ ": \"" + t.toString() + "\"" ];
				else if (t.toString!=null)
					a[a.length] = [ p+ ": " + t.toString()];
				else if (t && typeof t == "object")
					a[a.length]= p + ":{ " + arguments.callee(t).join(", ") + "}";
				else
					a[a.length] = [ p+ ": unknown"];
			}
	    }
	    return a;
	}

	return "{" + parse(o).join(", ") + "}";
    },

    createPoint(coordinates, properties) {
        return {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: coordinates
            },
            properties: properties ? properties : {}
        };
    }
}

export default VTUtil;
